import React, {ChangeEvent} from 'react';
import {Button, Icon, Label} from "semantic-ui-react";

type Props = {
    onChange: (file: File | null) => void;
    value: File | null;
    inline?: boolean;
    placeholder: string;
    className?: string;
    name?: string;
    disabled?: boolean;
};

const FileUploader = (props: Props) => {
    const inputRef = React.createRef<HTMLInputElement>();

    return <>
        <Button
            as="div"
            style={{display: props.inline ? 'inline-flex' : 'flex'}}
            className={props.className}
            labelPosition="right"
            disabled={props.disabled}
            onClick={() => inputRef.current!.click()}>
            <Button icon
                    title="Add another file">
                <Icon name="upload"/>
            </Button>
            <Label as="a"
                   basic
                   pointing="left">
                {props.value?.name ?? props.placeholder}
            </Label>
        </Button>

        <input
            ref={inputRef}
            name={props.name}
            hidden
            onChange={onChange}
            type="file"
        />
    </>

    function onChange(e: ChangeEvent<HTMLInputElement>) {
        const file = e.currentTarget.files?.[0] ?? null;
        props.onChange(file);
    }
};

export default FileUploader;
