import { statusColor } from '../../../../helpers/colors.helper';
import { CategoryId, GroupId, PersonId } from '../../../../models/aliases';
import { Category, Group, Team } from '../../../../models/core';
import { Services } from '../../../../models/services';
import { Snapshot } from '../../../../models/snapshot';
import { Type } from '../../../../models/type';
import { GraphData, GraphLink, GraphNode } from '../graph-page-view';
import { Force, INNOVATIONS_ID, ROLES_ID } from './shared';

/**
 * Creates view cencetered on the provided team.
 * Containing the group and super groups the teams falls under,
 * as well as the roles of the team with the members falling under these rolls
 * and the taxonomy of the innovtions used by the team, color coded based on their usage status.
 * @param team
 * @param snapshot
 * @param services
 */
export default async function teamGraph(team: Team, snapshot: Snapshot, services: Services): Promise<GraphData> {
    const usagesPromise = services.usages.getUsagesByTeamId(team.id);

    const nodes: GraphNode[] = [];
    const links: GraphLink[] = [];

    const defaultNode: GraphNode = { type: Type.Team, id: team.id, name: team.name, data: team };
    nodes.push(defaultNode);

    nodes.push({ type: Type.Dummy, id: ROLES_ID, name: 'Rollen' });
    links.push({ type: Type.Dummy, source: ROLES_ID, target: team.id, force: Force.Large });

    nodes.push({ type: Type.Dummy, id: INNOVATIONS_ID, name: 'Innovaties' });
    links.push({ type: Type.Dummy, source: INNOVATIONS_ID, target: team.id, force: Force.Medium });

    let source = team.id;
    let groupId: GroupId | null = team.group;
    do {
        const group: Group = snapshot.groupMap.get(groupId)!!;
        nodes.push({ type: Type.Group, id: group.id, name: group.name, data: group });
        links.push({ type: Type.Group, source, target: group.id, force: Force.Small });
        source = groupId;
        groupId = group.superGroup || null;
    } while (groupId);

    const uniquePersons = new Set<PersonId>();

    team.roles.forEach(role => {
        nodes.push({ type: Type.Role, id: role.id, name: role.name, data: role });
        links.push({ type: Type.Role, source: role.id, target: ROLES_ID, force: Force.Large });
        role.members.forEach(member => {
            links.push({ type: Type.Person, source: member, target: role.id, name: role.name, force: Force.Large });
            uniquePersons.add(member);
        });
    });

    uniquePersons.forEach(personId => {
        const person = snapshot.personMap.get(personId)!!;
        nodes.push({ type: Type.Person, id: person.id, name: person.name, data: person });
    });

    const uniqueCategories = new Set<CategoryId>();

    (await usagesPromise).forEach(usage => {
        const innovation = snapshot.innovationMap.get(usage.innovation)!!;
        nodes.push({
            type: Type.Usage,
            id: usage.id,
            name: innovation.name,
            data: usage,
            blipColor: statusColor(usage.status)
        });
        links.push({ type: Type.Category, source: usage.id, target: innovation.category, force: Force.Large });

        let categoryId: CategoryId | null = innovation.category;
        while (categoryId && !uniqueCategories.has(categoryId)) {
            uniqueCategories.add(categoryId);
            const category: Category = snapshot.categoryMap.get(categoryId)!!;
            nodes.push({
                type: Type.Category,
                id: category.id,
                name: category.name,
                data: category
            });
            if (category.superCategory) {
                links.push({
                    type: Type.Category,
                    source: categoryId,
                    target: category.superCategory,
                    force: Force.Medium
                });
            } else {
                links.push({ type: Type.Category, source: categoryId, target: INNOVATIONS_ID, force: Force.Medium });
            }
            categoryId = category.superCategory;
        }
    });

    return { nodes, links, defaultNode };
}
