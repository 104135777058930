import React, { ReactElement } from 'react';
import { Divider } from 'semantic-ui-react';
import { personToFullName } from '../../../../helpers/person.helper';
import { PersonId } from '../../../../models/aliases';
import { Role, Team } from '../../../../models/core';
import { Services } from '../../../../models/services';
import { Snapshot } from '../../../../models/snapshot';
import { Type } from '../../../../models/type';
import BaseView from '../../../shared/modals/base-view';
import EditTeam from '../../../shared/modals/edit-modals/edit-team';
import EditTeamInnovations from '../../../shared/modals/edit-modals/edit-team-innovations';
import EditTeamMembers from '../../../shared/modals/edit-modals/edit-team-members';
import RichEditor from '../../../shared/rich-editor';
import TypeLink from '../../../shared/sidebar-link/type-link';
import Sidebar from './sidebar';

export default class SidebarTeam extends Sidebar<Team> {

    protected hasAccessTo(view: typeof BaseView): boolean {
        const team = this.props.data;
        const isTeamMember = this.props.user.teams?.has(team.id) ?? false;
        const viewsAllowedToSee: Set<typeof BaseView> = new Set([
            EditTeam,
            EditTeamMembers,
            EditTeamInnovations
        ]);
        return isTeamMember && viewsAllowedToSee.has(view);
    }

    protected renderContent(team: Team, snapshot: Snapshot, services: Services): React.ReactElement | null {
        return (
            <>
                <Divider horizontal
                         content="Rollen & Leden"/>
                <div className="tw-flex tw-flex-col tw-gap-4">
                    {this.renderRoles(team.roles)}
                </div>
                <Divider horizontal
                         content="Omschrijving"/>
                <RichEditor initialMarkdown={team.description}
                            placeholder="Geen omschrijving"
                            readonly/>
            </>
        );
    }

    private renderRoles(roles: Role[]): ReactElement[] {
        if (roles.length === 0) {
            return [
                <i key="no_roles">Dit team heeft geen rollen</i>
            ];
        }

        return roles.map((role, index) => {
            return <div key={role.id}
                        className="tw-mb-2">
                <strong>{role.name}</strong>
                {role.description &&
                <RichEditor initialMarkdown={role.description}
                            readonly
                            className="tw-mb-4"/>}
                {this.renderMembers(role.members)}
            </div>;
        });
    }

    private renderMembers(members: PersonId[]) {
        return members.map(personId => {
            const person = this.props.snapshot.personMap.get(personId)!!;
            const func = this.props.snapshot.functionMap.get(person.function)!!;
            return <div key={personId}
                        className="tw-flex tw-justify-between">
                <TypeLink name={personToFullName(person)}
                          type={Type.Person}
                          id={person.id}/>
                <TypeLink name={func.name}
                          type={Type.Function}
                          id={func.id}/>
            </div>;
        });
    }
}
