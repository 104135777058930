import React, { CSSProperties } from 'react';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

type Props = {
    color: string;
    style?: CSSProperties;
    className?: string;
};

const Blip = (props: Props) => {
    return <div
        className={'tw-rounded-full tw-w-4 tw-h-4 tw-inline-block ' + props.className}
        style={{
            backgroundColor: props.color,
            ...props.style
        }}/>;
};

export default Blip;
