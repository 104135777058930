import React from 'react';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { statusColor } from '../../../helpers/colors.helper';
import { UsageStatus } from '../../../models/core';
import Blip from '../blip/blip';

type Props = {
    status: UsageStatus;
};

const UsageStatusDisplay = (props: Props) => {
    return <div className="tw-flex tw-flex-wrap">
        {renderStatus(UsageStatus.EXPERIMENT, 'Experimenteren')}
        {renderStatus(UsageStatus.ADOPT, 'Adopteren')}
        {renderStatus(UsageStatus.HOLD, 'Continueren')}
        {renderStatus(UsageStatus.SCRAP, 'Uitfaseren')}
    </div>;

    function renderStatus(status: UsageStatus, label: string) {
        const color = statusColor(status);
        const isActive = status === props.status;
        return <div className="tw-w-1/2 tw-px-2 tw-py-3 tw-relative tw-leading-4"
                    style={{
                        color,
                        ...isActive ? {
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: color
                        } : undefined
                    }}>
            {isActive && <div className="tw-absolute tw-top-0 tw-left-0 tw-opacity-20 tw-w-full tw-h-full"
                              style={{
                                  backgroundColor: color
                              }}/>
            }
            <Blip color={color}
                  className="tw-mr-2 tw-float-left"/>
            {label}
        </div>;
    }
};

export default UsageStatusDisplay;
