import React, { Component, ReactElement } from 'react';
import { Dimmer, Loader, Modal, Tab } from 'semantic-ui-react';
import BaseView, { BaseViewProps } from './base-view';

export type Tab = {
    view: typeof BaseView;
    name?: string;
}

export type TabbedModalProps = {
    viewProps: BaseViewProps
    tabs: Tab[]
}

type State = {
    title: string;
    actions: ReactElement | null;
    loading: boolean;
    loadingMessage: string | null;
}

export default class TabbedModal extends Component<TabbedModalProps, State> {

    readonly state = {
        title: '?',
        actions: null,
        loading: false,
        loadingMessage: null
    };

    render() {
        return <>
            <Modal open
                   closeIcon
                   style={{ width: 'calc(85vw)' }}
                   onClose={this.onCloseRequested.bind(this)}>
                <Modal.Header>
                    <h1 className="tw-text-4xl">{this.state.title}</h1>
                </Modal.Header>
                <Modal.Content scrolling
                               className="tw-max-h-none">
                    <Dimmer active={this.state.loading}>
                        <Loader content={this.state.loadingMessage}/>
                    </Dimmer>
                    {this.renderTabs()}
                </Modal.Content>
                {this.renderActions()}
            </Modal>
        </>;
    }

    private renderTabs() {
        const tabs = this.props.tabs;
        if (tabs.length < 2) {
            return React.createElement(tabs[0].view as any, this.viewProps());
        }
        return <Tab defaultActiveIndex="0"
                    menu={{ secondary: true, pointing: true }}
                    panes={this.tabPanes()}/>;
    }

    private tabPanes() {
        return this.props.tabs.map(tab => {
            const element = React.createElement(tab.view as any, this.viewProps());
            return {
                menuItem: tab.name ?? '?',
                render: () => <Tab.Pane className="tw-border-none tw-p-0">{element}</Tab.Pane>
            };
        });
    }

    private renderActions(): ReactElement | null {
        if (!this.state.actions) {
            return null;
        }
        return <Modal.Actions>{this.state.actions}</Modal.Actions>;
    }

    private viewProps() {
        return {
            ...this.props.viewProps,
            onSetTitle: (title: string) => this.setState({ title }),
            onSetActions: (actions: ReactElement | null) => this.setState({ actions }),
            onSetLoading: (loading: boolean, loadingMessage: string | null) =>
                this.setState({ loading, loadingMessage })
        };
    }

    private onCloseRequested() {
        if (!this.state.loading) {
            this.props.viewProps.onClose();
        }
    }
}
