import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import React, { ReactElement } from 'react';
import { DropdownItemProps, FormDropdown, FormInput } from 'semantic-ui-react';
import * as Yup from 'yup';
import { generateTempId } from '../../../../helpers/global.helper';
import { findSuperCategories } from '../../../../helpers/innovation.helper';
import { CategoryId } from '../../../../models/aliases';
import { Category } from '../../../../models/core';
import { updateSnapshotData } from '../../../../models/snapshot';
import ErrorLabel from '../../../shared/error-label';
import RichEditor from '../../../shared/rich-editor';
import BaseViewEdit from '../base-view-edit';

export default class EditCategory extends BaseViewEdit<Category> {

    private formRef = React.createRef<FormikProps<any>>();

    protected initialiseState(): {} {
        return {};
    }

    protected getTitle(): string {
        const category = this.props.data;
        return category ? `${category.name}` : 'Nieuwe innovatie-categorie aanmaken';
    }

    protected renderContent(): ReactElement {
        const category = this.props.data;
        return <>
            <Formik
                initialValues={{
                    name: category?.name ?? '',
                    description: category?.description ?? null,
                    superCategory: category?.superCategory ?? null
                }}
                validationSchema={Yup.object({
                    name: Yup.string().required('Categorienaam is vereist').max(60, 'Maximale lengte 60'),
                    description: Yup.string().nullable().min(10, 'Minimale lengte 10').max(500, 'Maximale lengte 1000'),
                    superCategory: Yup.string().nullable()
                })}
                onSubmit={(values: FormikValues) => {
                    const category = {
                        ...(this.props.data ?? {
                            id: generateTempId()
                        }), ...values
                    } as Category;
                    this.save(category);
                }}
                innerRef={this.formRef}>
                {({ errors, setFieldTouched, setFieldValue, values }) =>
                    <Form>
                        <Field
                            name="name"
                            as={FormInput}
                            fluid
                            label="Categorienaam"
                            placeholder="Categorienaam"
                        />
                        <ErrorLabel content={errors.name as string}/>
                        <label>Omschrijving</label>
                        <Field
                            name="description"
                            as={RichEditor}
                            fluid
                            initialMarkdown={values.description}
                            placeholder="Omschrijving"
                            onChange={(markdown: string) => setFieldValue('description', markdown)}
                            onBlur={() => setFieldTouched('description', true)}
                        />
                        <ErrorLabel content={errors.description as string}/>
                        <Field
                            name="superCategory"
                            as={FormDropdown}
                            selection
                            search
                            options={this.superCategoryOptions(category)}
                            fluid
                            label="Supercategorie"
                            placeholder="Geen"
                            onBlur={(e: any, { name }: any) => setFieldTouched(name, true)}
                            onChange={(e: any, { name, value }: any) => {
                                setFieldValue(name, value);
                            }}
                        />
                        <ErrorLabel content={errors.superCategory as string}/>
                    </Form>
                }
            </Formik>
        </>;
    }

    private superCategoryOptions(baseCategory: Category | null): DropdownItemProps[] {
        let availableCategories = this.props.snapshot.categories;
        if (baseCategory) {
            // Disallow category if it is a sub-category of the base category
            const categoryMap = this.props.snapshot.categoryMap;
            availableCategories = availableCategories.filter(it => {
                let currentId: CategoryId | null = it.id;
                do {
                    if (currentId === baseCategory.id) {
                        return false;
                    }
                    currentId = categoryMap.get(currentId)!!.superCategory;
                } while (currentId !== null);
                return true;
            });
        }

        const options = availableCategories.map<DropdownItemProps>(category => {
            const categoryMap = this.props.snapshot.categoryMap;
            const superCategories = findSuperCategories(category.superCategory, categoryMap)
                .map(it => it.name).join(' < ');

            return {
                text: category.name,
                value: category.id,
                description: superCategories.length ? `< ${superCategories}` : '-'
            };
        });

        options.unshift({
            content: <i>Geen</i>,
            // @ts-ignore
            value: null,
            key: 'none'
        });

        return options;
    }

    protected onSubmit() {
        this.formRef.current?.handleSubmit();
    }

    protected async processAdd(category: Category): Promise<void> {
        return this.props.services.categories.addCategory(category).then((category: Category) =>
            updateSnapshotData(category, this.props.snapshot.categories, this.props.snapshot.categoryMap));
    }

    protected async processUpdate(category: Category): Promise<void> {
        return this.props.services.categories.updateCategory(category.id, category).then((category: Category) =>
            updateSnapshotData(category, this.props.snapshot.categories, this.props.snapshot.categoryMap));
    }

}
