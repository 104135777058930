import { CategoryId } from '../models/aliases';
import { Category } from '../models/core';
import ApiService from './api-service';

export default class CategoryService extends ApiService {

    async getCategories(date: Date | null): Promise<Category[]> {
        const path = `/categories/`;
        return await this.get(path, date ?? undefined);
    }

    async addCategory(category: Category): Promise<Category> {
        const path = `/categories`;
        const nullCategory = this.ensureNoTempIds(category)[0];
        return await this.post(path, nullCategory);
    }

    async updateCategory(categoryId: CategoryId, category: Category): Promise<Category> {
        const path = `/categories/${categoryId}`;
        const nullCategory = this.ensureNoTempIds(category)[0];
        return await this.patch(path, nullCategory);
    }

    async deleteCategory(categoryId: CategoryId): Promise<void> {
        const path = `/categories/${categoryId}`;
        return await this.delete(path);
    }

}
