import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { toPath } from '../../../helpers/global.helper';
import { findNodeId } from '../../../helpers/graph.helper';
import { Experience, Usage } from '../../../models/core';
import { Services } from '../../../models/services';
import { Snapshot } from '../../../models/snapshot';
import { Type, TypeConfig, typeFromString, Types, typeToString } from '../../../models/type';
import { User } from '../../../models/user';
import BaseView from '../../shared/modals/base-view';
import { Tab } from '../../shared/modals/tabbed-modal';
import { onChangeDate } from '../app-container';
import GraphPageView, { GraphData, GraphNode } from './graph-page-view';

export type onOpenView = (tabs: Tab[], typeConfig: TypeConfig, props?: any) => void;
export type onNavigateToData = (type: Type, name?: string, id?: string, data?: object) => void;

export type Props = {
    snapshot: Snapshot;
    services: Services;
    user: User;
    onChangeDate: onChangeDate;
} & RouteComponentProps<Params>;

type State = {
    graphData: GraphData | null;
    graphDataTimestamp: number;
    selectedNode: GraphNode | null;
    centeredNode: GraphNode | null;
    openViews: Array<ViewStackEntry>;
    error: Error | null;
    loading: boolean;
};

export type ViewStackEntry = {
    tabs: Tab[];
    view: typeof BaseView;
    typeConfig: TypeConfig;
    props: any;
}

type Params = {
    nodeType: string;
    nodeName: string;
    nodeId: string;
}

const initialState: State = {
    graphData: null,
    graphDataTimestamp: 0,
    selectedNode: null,
    centeredNode: null,
    openViews: new Array<ViewStackEntry>(),
    error: null,
    loading: true
};

export default class GraphPageContainer extends Component<Props, State> {

    public readonly state = initialState;

    async componentDidMount() {
        await this.updateGraph();
    }

    async componentDidUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any) {
        const urlHasChanged = this.props.location !== nextProps.location;
        const snapshotHasChanged = this.props.snapshot.date?.getTime() !== nextProps.snapshot.date?.getTime();
        if (urlHasChanged || snapshotHasChanged) {
            this.setState({ loading: true });
            await this.updateGraph();
        }
    }

    render() {
        return React.createElement(GraphPageView, {
            snapshot: this.props.snapshot,
            services: this.props.services,
            user: this.props.user,
            graphData: this.state.graphData,
            graphDataTimestamp: this.state.graphDataTimestamp,
            selectedNode: this.state.selectedNode,
            centeredNode: this.state.centeredNode,
            onNodeClick: this.onNodeClick.bind(this),
            onNodeDoubleClick: this.onNodeDoubleClick.bind(this),
            onNavigateToData: this.navigateToData.bind(this),
            onChangeDate: this.props.onChangeDate,
            openViews: this.state.openViews,
            onOpenView: this.onOpenModal.bind(this),
            onCloseView: this.onCloseModal.bind(this),
            loading: this.state.loading,
            error: this.state.error
        });
    }

    private async updateGraph() {
        const params = this.props.match.params as Params;

        const nodeType = typeFromString(params.nodeType) ?? Type.Group;

        const graphData = await this.buildGraph(nodeType, params.nodeId);
        const graphDataTimestamp = this.props.snapshot.date?.getTime() ?? 0;

        const centeredNodeId = params.nodeId ?? this.state.centeredNode?.id;
        const centeredNode = graphData.nodes.find(it => it.id === centeredNodeId) ?? null;
        const selectedNode = centeredNode ?? graphData.defaultNode;

        this.setState({
            graphData,
            graphDataTimestamp,
            centeredNode,
            selectedNode,
            error: null,
            loading: false
        });
    }

    /**
     * Builds graph data based on the provided nodes.
     * Centers on the top-level Group when no valid centered node is provided.
     * @param nodeType the DataType of the centered node
     * @param nodeId the UUID of the centered node
     * @returns the GraphData and the GraphNode the graph is centered on
     */
    private async buildGraph(nodeType: Type, nodeId: string): Promise<GraphData> {
        const snapshot = this.props.snapshot;
        const services = this.props.services;
        const emptyGraphData: GraphData = { nodes: [], links: [], defaultNode: null };

        const graphFunction = Types[nodeType].graph;
        if (!graphFunction) {
            return emptyGraphData;
        }

        return await graphFunction(nodeId, snapshot, services) ?? emptyGraphData;
    }

    private onNodeClick(node: GraphNode) {
        if (node) {
            this.setState({ selectedNode: node });
        }
    }

    private onNodeDoubleClick(node: GraphNode) {
        if (node.data !== undefined) {
            this.navigateToData(node.type, node.name, findNodeId(node), node.data);
        }
    }

    private navigateToData(type: Type, name?: string, id?: string, data?: object) {
        let urlType = type;
        let urlName = name;
        let urlId = id;

        // For irregular behaviour when navigating to said node
        switch (type) {
            case Type.Dummy:
            case Type.Role:
                return;
            case Type.Usage:
            case Type.Experience:
                const reference = data as Usage | Experience;
                const innovation = this.props.snapshot.innovationMap.get(reference.innovation)!!;
                urlType = Type.Innovation;
                urlName = innovation.name;
                urlId = innovation.id;
                break;
        }

        urlName = urlName?.replaceAll(' ', '_');
        const urlTypeName = typeToString(urlType);
        const path = urlName && urlId ? toPath(urlTypeName, urlName, urlId) : toPath(urlTypeName);
        this.props.history.push(path);
    }

    private onOpenModal(tabs: Tab[], typeConfig: TypeConfig, props?: any) {
        this.setState(state => {
            state.openViews.push({ tabs, typeConfig, props } as ViewStackEntry);
            return state;
        });
    }

    private onCloseModal() {
        this.setState(state => {
            state.openViews.pop();
            if (state.openViews.length === 0) {
                this.updateGraph().then();
            }
            return state;
        });
    }
}
