export const ROLES_ID = 'roles';
export const INNOVATIONS_ID = 'innovations';
export const EXPERIENCES_ID = 'experiences';
export const FUNCTIONS_ID = 'functions';

// Used to impact how cohesive groups of nodes are based on their link strength
export const Force = {
    Tiny: 0.1,
    Small: 0.25,
    Medium: 0.5,
    Large: 1.0,
    Huge: 2.0,
};
