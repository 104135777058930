import React from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { Button } from 'semantic-ui-react';
import { deepCopy } from '../../../helpers/global.helper';
import { Snapshot } from '../../../models/snapshot';
import { Type } from '../../../models/type';
import { User } from '../../../models/user';
import { onChangeDate } from '../../app/app-container';
import { onNavigateToData, onOpenView } from '../../app/graph-page/graph-page-container';
import AdminDropdown from '../admin-dropdown/admin-dropdown';
import GraphDropdown from '../graph-dropdown/graph-dropdown';

type Props = {
    snapshot: Snapshot;
    user: User;
    activeGraphType: Type | null;
    onOpenAdminModal: onOpenView;
    onChangeDate: onChangeDate;
    onZoom: (zoomAmount: number) => void;
    onNavigateToData: onNavigateToData;
};

const GraphUI = (props: Props) => {

    const dateSetter = (date?: Date) => {
        date?.setDate(date.getDate() - 1);
        return date;
    };

    return <>
        <div className="tw-absolute tw-left-0 tw-bottom-0 tw-p-4 tw-flex tw-flex-col-reverse tw-gap-4">
            <SemanticDatepicker value={dateSetter(deepCopy(props.snapshot.date))}
                                pointing="top left"
                                placeholder="Tijdreizen"
                                className={'tw-w-40 ' + (props.snapshot.date
                                    ? 'tw-rounded tw-border tw-border-teal'
                                    : undefined)}
                                onChange={((event, data) => {
                                    handleTimeTravel(data.value as Date | undefined ?? null);
                                })}/>
            <GraphDropdown activeGraphType={props.activeGraphType}
                           className="tw-w-40"
                           onNavigateToData={props.onNavigateToData}/>
        </div>
        <div className="tw-absolute tw-right-0 tw-bottom-0 tw-p-4">
            <AdminDropdown user={props.user}
                           onOpenAdminModal={props.onOpenAdminModal}
                           disabled={props.snapshot.date !== null}
                           className="tw-w-40"/>
            <Button icon="minus"
                    onClick={() => props.onZoom(0.6)}/>
            <Button icon="plus"
                    onClick={() => props.onZoom(1.4)}/>
        </div>
    </>;

    function handleTimeTravel(date: Date | null) {
        let dateToSend = deepCopy(date);
        if (date) {
            date.setDate(date.getDate() + 1);
            dateToSend = deepCopy(date);
            date.setDate(date.getDate() - 1);
        }
        props.onChangeDate(dateToSend);
    }
};

export default GraphUI;
