import { generateRandomId } from '../../../../helpers/graph.helper';
import { Snapshot } from '../../../../models/snapshot';
import { Type } from '../../../../models/type';
import { GraphData, GraphLink, GraphNode } from '../graph-page-view';
import { Force } from './shared';

/**
 * Creates an organogram containing Groups, Teams and team members directly coupled to the team nodes
 * @param snapshot
 */
export default async function groupGraph(snapshot: Snapshot): Promise<GraphData> {
    const nodes: GraphNode[] = [];
    const links: GraphLink[] = [];

    let defaultNode: GraphNode | null = null;

    snapshot.groups.forEach(group => {
        const groupNode: GraphNode = { type: Type.Group, id: group.id, name: group.name, data: group };
        nodes.push(groupNode);
        if (group.superGroup) {
            links.push({ type: Type.Group, source: group.id, target: group.superGroup, force: Force.Small });
        } else {
            defaultNode = groupNode;
        }
    });

    snapshot.teams.forEach(team => {
        nodes.push({ type: Type.Team, id: team.id, name: team.name, data: team });
        links.push({ type: Type.Team, source: team.id, target: team.group, force: Force.Small });

        const uniquePersons = new Set<string>();
        team.roles.forEach(role => {
            role.members.forEach(uniquePersons.add.bind(uniquePersons));
        });

        for (const personId of uniquePersons.values()) {
            const person = snapshot.personMap.get(personId)!!;
            const nodeId = generateRandomId();
            links.push({ type: Type.Person, source: nodeId, target: team.id, force: Force.Large });
            nodes.push({ type: Type.Person, id: nodeId, name: person.name, data: person });
        }
    });

    return { nodes, links, defaultNode };
}
