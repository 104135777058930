import Keycloak from 'keycloak-js';
import React, { Component } from 'react';
import { newError } from '../../helpers/global.helper';
import { PersonId, TeamId } from '../../models/aliases';
import { Person, Team } from '../../models/core';
import { Services } from '../../models/services';
import { Snapshot } from '../../models/snapshot';
import { User } from '../../models/user';
import CategoryService from '../../services/category-service';
import ExperienceService from '../../services/experience-service';
import FunctionService from '../../services/function-service';
import GroupService from '../../services/group-service';
import InnovationService from '../../services/innovation-service';
import PersonService from '../../services/person-service';
import TeamService from '../../services/team-service';
import UsageService from '../../services/usage-service';
import AppView from './app-view';

export type Props = {};
export type onChangeDate = (date: Date | null) => void;
export type State = {
    user: User | null;
    services: Services | null;
    snapshot: Snapshot | null;
    error: Error | null;
};

const initialState: State = {
    user: null,
    services: null,
    snapshot: null,
    error: null
};

export default class AppContainer extends Component<Props, State> {

    readonly state = initialState;

    async componentDidMount() {
        this.initialiseApp().then(it => {
            this.setState(it);
        }).catch(e => {
            console.error(e);
            this.setState({
                error: newError('Er is iets misgegaan bij het initialiseren!', e.toString())
            });
        });
    }


    render() {
        return React.createElement(AppView, {
            user: this.state.user,
            services: this.state.services,
            snapshot: this.state.snapshot,
            onChangeDate: this.onChangeDate.bind(this),
            error: this.state.error
        });
    }

    private async onChangeDate(date: Date | null) {
        const snapshot = await this.initSnapshot(this.state.services!!, date);
        const user = this.state.user!!;
        user.teams = this.findTeamsOfUser(snapshot.teams, snapshot.personMap, user.email);

        this.setState({ user, snapshot });
    }

    private async initialiseApp(): Promise<State> {
        const user = await this.initAuthentication();
        const services = this.initServices(user.token);
        const snapshot = await this.initSnapshot(services, null);
        user.teams = this.findTeamsOfUser(snapshot.teams, snapshot.personMap, user.email);

        return {
            user,
            services,
            snapshot,
            error: null
        };
    }

    private async initAuthentication(): Promise<User> {
        const keycloak = Keycloak({
            url: process.env.REACT_APP_KEYCLOAK_URL!!,
            realm: process.env.REACT_APP_KEYCLOAK_REALM!!,
            clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID!!
        });

        keycloak.onTokenExpired = () => {
            keycloak.updateToken(10).then(() => {
                if (!keycloak.token) {
                    window.location.reload();
                } else {
                    this.updateToken(keycloak.token);
                }
            }).catch(() => {
                // Session has expired
                window.location.reload();
            });
        };

        await keycloak.init({
            onLoad: 'login-required'
        });

        const profile = await keycloak.loadUserProfile();
        const roles = new Set(keycloak.realmAccess?.roles);

        return {
            email: profile.email ?? '',
            name: profile.firstName ?? '',
            surname: profile.lastName ?? '',
            token: keycloak.token!!,
            roles,
            teams: null
        };
    }

    /**
     * Updates the user and services to make use of a new token
     * @param token
     * @private
     */
    private updateToken(token: string) {
        this.setState(state => {
            const user = state.user!!;
            user.token = token;
            return {
                user,
                services: this.initServices(token)
            };
        });
    }

    /**
     * Sets up the services using the given authentication token. This token is
     * passed to each services so it can make authenticated requests.
     * @param token
     */
    private initServices(token: string): Services {
        return {
            groups: new GroupService(token),
            teams: new TeamService(token),
            persons: new PersonService(token),
            functions: new FunctionService(token),
            innovations: new InnovationService(token),
            categories: new CategoryService(token),
            usages: new UsageService(token),
            experiences: new ExperienceService(token)
        };
    }

    /**
     * Returns the generic data at the current moment of time existing in the tech radar
     * @param services
     * @param date is the validdatetime. This parameter is set when looking at historic data.
     */
    private async initSnapshot(services: Services, date: Date | null): Promise<Snapshot> {
        const snapshotArray = await Promise.all([
            services.groups.getGroups(date),
            services.teams.getTeams(date),
            services.persons.getPersons(date),
            services.functions.getFunctions(date),
            services.categories.getCategories(date),
            services.innovations.getInnovations(date)
        ]);
        return {
            date: date,
            groups: snapshotArray[0],
            groupMap: new Map(snapshotArray[0].map(i => [i.id, i])),
            teams: snapshotArray[1],
            teamMap: new Map(snapshotArray[1].map(i => [i.id, i])),
            persons: snapshotArray[2],
            personMap: new Map(snapshotArray[2].map(i => [i.id, i])),
            functions: snapshotArray[3],
            functionMap: new Map(snapshotArray[3].map(i => [i.id, i])),
            categories: snapshotArray[4],
            categoryMap: new Map(snapshotArray[4].map(i => [i.id, i])),
            innovations: snapshotArray[5],
            innovationMap: new Map(snapshotArray[5].map(i => [i.id, i]))
        };
    }

    private findTeamsOfUser(teams: Team[], personMap: Map<PersonId, Person>, userEmail: string): Set<TeamId> {
        const memberOfTeams = new Set<TeamId>();
        teamLoop:
            for (const team of teams) {
                for (const role of team.roles) {
                    for (const teamMember of role.members) {
                        if (personMap.get(teamMember)?.email === userEmail) {
                            memberOfTeams.add(team.id);
                            continue teamLoop;
                        }
                    }
                }
            }
        return memberOfTeams;
    }

}
