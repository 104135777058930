import { InnovationId, TeamId } from '../models/aliases';
import { Role, Team } from '../models/core';
import ApiService from './api-service';

export default class TeamService extends ApiService {

    async getTeams(date: Date | null): Promise<Team[]> {
        const path = `/teams/`;
        return await this.get(path, date ?? undefined);
    }

    async getTeamsUsingInnovation(innovationId: InnovationId, date: Date | null): Promise<TeamId[]> {
        const path = `/teams/innovations/${innovationId}`;
        return await this.get(path, date ?? undefined);
    }

    async updateTeamRoles(teamId: TeamId, roles: Role[]): Promise<Role[]> {
        const path = `/teams/${teamId}/roles`;
        const nullRoles = this.ensureNoTempIds(...roles);
        return await this.post(path, nullRoles);
    }

    async addTeam(team: Team): Promise<Team> {
        const path = `/teams`;
        const nullTeam = this.ensureNoTempIds(team)[0];
        return await this.post(path, nullTeam);
    }

    async updateTeam(teamId: TeamId, team: Team): Promise<Team> {
        const path = `/teams/${teamId}`;
        const nullTeam = this.ensureNoTempIds(team)[0];
        return await this.patch(path, nullTeam);
    }

    async deleteTeam(teamId: TeamId): Promise<void> {
        const path = `/teams/${teamId}`;
        return await this.delete(path);
    }

}
