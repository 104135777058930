import React from 'react';
import { Divider } from 'semantic-ui-react';
import { Experience, Innovation } from '../../../../models/core';
import { Services } from '../../../../models/services';
import { Snapshot } from '../../../../models/snapshot';
import RichEditor from '../../../shared/rich-editor';
import Sidebar from './sidebar';

export default class SidebarExperience extends Sidebar<Experience> {

    protected renderContent(experience: Experience, snapshot: Snapshot, services: Services): React.ReactElement | null {
        const innovation = this.props.snapshot.innovationMap.get(experience.innovation)!!;

        return <>
            {this.renderInnovation(innovation)}
        </>;
    }

    private renderInnovation(innovation: Innovation) {
        return <>
            <Divider horizontal
                     content="Innovatie"/>
            <RichEditor initialMarkdown={innovation.description}
                        placeholder="Geen omschrijving beschikbaar"
                        readonly/>
        </>;
    }
}
