export function toPath(...pathParts: string[]): string {
    return '/' + pathParts.map(encodeURIComponent).join('/');
}

let generateTempIdCount = 0;

/**
 * All generated temp id's start with this prefix
 */
export const TEMP_ID_PREFIX = 'temp-';

/**
 * Guaranteed to be unique.
 * Used to identify models with that have not yet been send to the backend.
 * After sending a post to the backend, the actual ids will be assigned and new objects returned.
 */
export function generateTempId(): string {
    return TEMP_ID_PREFIX + generateTempIdCount++;
}

export function newError(name: string, message?: string): Error {
    return {
        name,
        message: message ?? 'Onbekende fout'
    } as Error;
}

export function setWindowTitle(...sections: (string | undefined | null)[]) {
    sections.unshift('Innovision');
    document.title = sections.filter(Boolean).join(' | ');
}

export function lerp(a: number, b: number, f: number): number {
    return a + f * (b - a);
}

export function average(a: number, b: number): number {
    return (a + b) * 0.5;
}

export async function delay(milliseconds: number) {
    await new Promise(resolve => setTimeout(() => resolve(), milliseconds));
}

export const TAU = Math.PI * 2;

export function deepCopy(obj: any): any {
    let copy: any;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' != typeof obj) {
        return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (let i = 0, len = obj.length; i < len; i++) {
            copy[i] = deepCopy(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (let attr in obj) {
            if (obj.hasOwnProperty(attr)) {
                copy[attr] = deepCopy(obj[attr]);
            }
        }
        return copy;
    }

    throw new Error('Unable to copy obj! Its type isn\'t supported.');
}
