/**
 * This creates a superclass for requests to the backend. It contains common
 * logic and abstracts away the fetch layer.
 */
import buildUrl from 'build-url-ts';
import { newError, TEMP_ID_PREFIX } from '../helpers/global.helper';

export default class ApiService {
    private readonly token: string;

    constructor(token: string) {
        this.token = token;
    }

    /**
     * Make an authenticated GET request to the backend.
     * @param path
     * @param date
     */
    protected async get(path: string, date?: Date): Promise<any> {
        const url = this.buildApiUrl(path, { 'date': date ? date.toISOString() : '' });
        const result: Response = await fetch(url, {
                ...this.getDefaultOptions(),
                method: 'GET'
            })
        ;

        return this.processResult(result);
    }

    /**
     * Make an authenticated POST request to the backend.
     * @param path
     * @param data
     */
    protected async post(path: string, data: object): Promise<any> {
        const url = this.buildApiUrl(path);
        const result: Response = await fetch(url, {
            ...this.getDefaultOptions(),
            method: 'POST',
            body: JSON.stringify(data)
        });
        return this.processResult(result);
    }

    /**
     * Make an authenticated DELETE request to the backend.
     * @param path
     * @param data
     */
    protected async delete(path: string, data?: object): Promise<any> {
        const url = this.buildApiUrl(path);
        const result: Response = await fetch(url, {
            ...this.getDefaultOptions(),
            method: 'DELETE',
            body: JSON.stringify(data)
        });
        return this.processResult(result);
    }

    /**
     * Make an authenticated PATCH request to the backend.
     * @param path
     * @param data
     */
    protected async patch(path: string, data?: object): Promise<any> {
        const url = this.buildApiUrl(path);
        const result: Response = await fetch(url, {
            ...this.getDefaultOptions(),
            method: 'PATCH',
            body: JSON.stringify(data)
        });
        return this.processResult(result);
    }

    /**
     * Models created on the front-end might contain temporary id's.
     * This is done to be able to maintain certainty that the id field of all models will never be null.
     * In order to post new models, the id field should be null.
     * This helper method ensures that the provided model array will have all its temp id fields set to null.
     * Temp id fields are identified by their TEMP_ID_PREFIX.
     * It will make a shallow copy of all model entries.
     * @param models
     * @protected
     */
    protected ensureNoTempIds(...models: any[]): any[] {
        return models.map(model => {
            if (model.id?.startsWith(TEMP_ID_PREFIX)) {
                return {
                    ...model,
                    id: null
                };
            }
            return model;
        });
    }

    /**
     * Takes the response from the backend and returns null if the server gives
     * us 204. 204 means No Content and thus we can't do .json() on it. Because,
     * it has no body to parse.
     * @param result
     */
    private async processResult(result: Response): Promise<null | any> {
        if (result.ok) {
            if (result.status === 204) {
                // No-Content
                return null;
            }
            try {
                return await result.json();
            } catch {
                return null;
            }
        }
        let errorMessage;
        try {
            errorMessage = (await result.json()).message;
        } catch {
            errorMessage = 'Onbekende oorzaak';
        }
        throw newError(`Error
        code ${result.status}`, errorMessage);
    }

    /**
     * Builds up the full URL with the given path. The base URL should be set in
     * a environment variable called REACT_APP_BASE_URL.
     * @param path
     * @param queryParams parameters to add to the query
     */
    private buildApiUrl(path: string, queryParams?: Record<string, any>): Request {
        const baseUrl: string = process.env.REACT_APP_BASE_URL!!;
        const url = baseUrl.replace(/\/$/, '') + '/';
        const to = ['api', path].join('/').replaceAll('//', '/').replace(/\/$/, '');
        return buildUrl(url, {
            path: to,
            queryParams: queryParams
        });
    }

    /**
     * Sets default headers including the Authorization header with the instance
     * token so it can make the request authenticated.
     */
    private getDefaultHeaders(): HeadersInit {
        return {
            Authorization: `Bearer ${this.token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
        };
    }

    /**
     * Sets the default options. In this case the default headers.
     */
    private getDefaultOptions(): RequestInit {
        return {
            headers: {
                ...this.getDefaultHeaders()
            }
        };
    }
}
