import React, { ReactElement } from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import { PersonId } from '../../../../models/aliases';
import { Person } from '../../../../models/core';
import { deleteSnapshotData } from '../../../../models/snapshot';
import { Type, Types } from '../../../../models/type';
import BaseViewAdmin from '../base-view-admin';
import EditPerson from '../edit-modals/edit-person';

export default class AdminPersons extends BaseViewAdmin {

    protected initialiseState(): {} {
        return {};
    }

    protected getTitle(): string {
        return 'Personeel beheren';
    }

    protected renderContent(): ReactElement {
        const sortedPersons = this.props.snapshot.persons.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        return <>
            <Button content="Persoon toevoegen"
                    className="tw-bg-teal tw-text-white hover:tw-bg-opacity-75"
                    onClick={_ => this.openEditor(null)}/>
            <Table basic="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Naam</Table.HeaderCell>
                        <Table.HeaderCell>E-mail</Table.HeaderCell>
                        <Table.HeaderCell>Functie</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Acties</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedPersons.map(this.renderPerson.bind(this))}
                </Table.Body>
            </Table>
        </>;
    }

    private renderPerson(person: Person): ReactElement {
        const functionMap = this.props.snapshot.functionMap;
        const func = functionMap.get(person.function)!!;
        return (
            <Table.Row key={person.id}>
                <Table.Cell>{[person.name, person.prefix, person.surname].join(' ')}</Table.Cell>
                <Table.Cell>{person.email}</Table.Cell>
                <Table.Cell>{func.name}</Table.Cell>
                <Table.Cell textAlign="right">
                    <Icon name="edit"
                          link
                          onClick={() => this.openEditor(person)}/>
                    <Icon name="trash"
                          link
                          color="red"
                          onClick={() => this.delete(person.id, person.name)}/>
                </Table.Cell>
            </Table.Row>
        );
    }

    private openEditor(person: Person | null) {
        const tabs = person ? this.allowEditTabs() : [{ view: EditPerson }];
        this.props.onOpenSubView(tabs, Types[Type.Person], { data: person });
    }

    protected async processDelete(personId: PersonId): Promise<void> {
        return this.props.services.persons.deletePerson(personId).then(() =>
            deleteSnapshotData(personId, this.props.snapshot.persons, this.props.snapshot.personMap));
    }

}
