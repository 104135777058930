import React, { ReactElement } from 'react';
import { Breadcrumb, Button, Icon, Table } from 'semantic-ui-react';
import { BreadcrumbSectionProps } from 'semantic-ui-react/dist/commonjs/collections/Breadcrumb/BreadcrumbSection';
import { findSuperCategories } from '../../../../helpers/innovation.helper';
import { InnovationId } from '../../../../models/aliases';
import { Innovation } from '../../../../models/core';
import { deleteSnapshotData } from '../../../../models/snapshot';
import { Type, Types } from '../../../../models/type';
import BaseViewAdmin from '../base-view-admin';
import EditInnovation from '../edit-modals/edit-innovation';

export default class AdminInnovations extends BaseViewAdmin {

    protected initialiseState(): {} {
        return {};
    }

    protected getTitle(): string {
        return 'Innovaties beheren';
    }

    protected renderContent(): ReactElement {
        const sortedInnovations = this.props.snapshot.innovations
            .sort((a, b) => a.name.localeCompare(b.name));

        return <>
            <Button content="Innovatie aanmaken"
                    className="tw-bg-teal tw-text-white hover:tw-bg-opacity-75"
                    onClick={_ => this.openEditor(null)}/>
            <Table basic="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Naam</Table.HeaderCell>
                        <Table.HeaderCell>Supercategorie</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Acties</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedInnovations.map(this.renderRow.bind(this))}
                </Table.Body>
            </Table>
        </>;
    }

    private renderRow(innovation: Innovation): ReactElement {
        const categoryMap = this.props.snapshot.categoryMap;
        const sections = findSuperCategories(innovation.category, categoryMap)
            .map<BreadcrumbSectionProps>(innovation => {
                return {
                    key: innovation.id,
                    content: innovation.name
                };
            });

        return (
            <Table.Row key={innovation.id}>
                <Table.Cell>{innovation.name}</Table.Cell>
                <Table.Cell> {
                    sections.length === 0 ? '-' :
                        <Breadcrumb icon="left angle"
                                    sections={sections}/>
                }
                </Table.Cell>
                <Table.Cell textAlign="right">
                    <Icon name="edit"
                          link
                          onClick={() => this.openEditor(innovation)}/>
                    <Icon name="trash"
                          link
                          color="red"
                          onClick={() => this.delete(innovation.id, innovation.name)}/>
                </Table.Cell>
            </Table.Row>
        );
    }

    private openEditor(innovation: Innovation | null) {
        const tabs = innovation ? this.allowEditTabs() : [{ view: EditInnovation }];
        this.props.onOpenSubView(tabs, Types[Type.Innovation], { data: innovation });
    }

    protected async processDelete(innovationId: InnovationId): Promise<void> {
        const innovation = this.props.snapshot.innovationMap.get(innovationId)!!;
        const teamsUsingInnovation = await this.props.services.teams.getTeamsUsingInnovation(
            innovationId,
            this.props.snapshot.date
        );

        if (!window.confirm(`${innovation.name} wordt gebruikt door ${teamsUsingInnovation.length} teams. Ben je zeker dat je deze innovatie wilt verwijderen?`)) {
            return;
        }

        return this.props.services.innovations.deleteInnovation(innovationId).then(() =>
            deleteSnapshotData(innovationId, this.props.snapshot.innovations, this.props.snapshot.innovationMap));
    }

}
