import React, {Component, ReactElement} from 'react';
import {Message} from "semantic-ui-react";
import {Snapshot} from "../../../models/snapshot";
import {TypeConfig} from "../../../models/type";
import {User} from "../../../models/user";
import {Services} from "../../../models/services";
import {onOpenView} from "../../app/graph-page/graph-page-container";

export type BaseViewProps = {
    snapshot: Snapshot;
    services: Services;
    user: User;

    typeConfig: TypeConfig;

    onSetTitle: (title: string) => void;
    onSetActions: (actions: ReactElement | null) => void;
    onSetLoading: (loading: boolean, loadingMessage: string | null) => void;
    onOpenSubView: onOpenView;
    onClose: () => void;
}

type State = {
    error: Error | null;
}

export default abstract class BaseView<P = {}, S = {}> extends Component<BaseViewProps & P, State & S> {

    readonly state: State & S = {
        error: null,
        ...this.initialiseState()
    };

    async componentDidMount() {
        this.props.onSetTitle(this.getTitle());
        this.props.onSetActions(this.getActions());
        return this.onMount();
    }

    protected async onMount() {

    }

    render() {
        return <>
            {this.renderContent()}
            {this.renderError()}
        </>
    }

    protected abstract initialiseState(): S;

    protected abstract getTitle(): string;

    protected getActions(): ReactElement | null {
        return null;
    }

    protected abstract renderContent(): ReactElement;

    protected renderError() {
        const error = this.state.error;
        if (!error) {
            return null;
        }

        return (
            <Message negative>
                <Message.Header>{error?.name}</Message.Header>
                <p>{error?.message}</p>
            </Message>
        );
    }
}
