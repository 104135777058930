import React, { ReactElement } from 'react';
import { Divider } from 'semantic-ui-react';
import { Category } from '../../../../models/core';
import { Services } from '../../../../models/services';
import { Snapshot } from '../../../../models/snapshot';
import RichEditor from '../../../shared/rich-editor';
import Sidebar from './sidebar';

export default class SidebarCategory extends Sidebar<Category> {

    protected renderContent(category: Category | null, snapshot: Snapshot, services: Services): ReactElement | null {
        return this.renderDescription(category!!);
    }

    private renderDescription(category: Category) {
        return <>
            <Divider horizontal
                     content="Omschrijving"/>
            <RichEditor initialMarkdown={category.description}
                        placeholder="Geen omschrijving"
                        readonly/>
        </>;
    }

}
