import { TeamId } from '../models/aliases';
import { Usage } from '../models/core';
import ApiService from './api-service';

export default class UsageService extends ApiService {

    async getUsages(date: Date | null): Promise<Usage[]> {
        const path = `/usages`;
        return await this.get(path, date ?? undefined);
    }

    async getUsagesByTeamId(teamId: TeamId): Promise<Usage[]> {
        const path = `/usages/${teamId}`;
        return await this.get(path);
    }

    async postTeamUsages(teamId: TeamId, usages: Usage[]): Promise<Usage[]> {
        const path = `/teams/${teamId}/usages`;
        const nullRoles = this.ensureNoTempIds(...usages);
        return await this.post(path, nullRoles);
    }

}
