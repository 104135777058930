import React from 'react';
import { Link } from 'react-router-dom';
import { toPath } from '../../../helpers/global.helper';
import { Type, Types, typeToString } from '../../../models/type';

type Props = {
    name: string;
    id: string;
    type: Type;
    className?: string;
};

function TypeLink(props: Props) {
    const name = props.name.replaceAll(' ', '_');
    const link = toPath(typeToString(props.type), name, props.id);
    return (
        <Link style={{ color: Types[props.type].color }}
              to={link}
              className={'hover:tw-underline ' + props.className}>
            {props.name}
        </Link>
    );
}

export default TypeLink;
