import React, { ReactElement } from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import { FunctionId } from '../../../../models/aliases';
import { Function } from '../../../../models/core';
import { deleteSnapshotData } from '../../../../models/snapshot';
import { Type, Types } from '../../../../models/type';
import BaseViewAdmin from '../base-view-admin';
import EditFunction from '../edit-modals/edit-function';

export default class AdminFunctions extends BaseViewAdmin {

    protected initialiseState(): {} {
        return {};
    }

    protected getTitle(): string {
        return 'Functies beheren';
    }

    protected renderContent(): ReactElement {
        const sortedFunctions = this.props.snapshot.functions.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        return <>
            <Button content="Functie aanmaken"
                    className="tw-bg-teal tw-text-white hover:tw-bg-opacity-75"
                    onClick={_ => this.openEditor(null)}/>
            <Table basic="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Naam</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Acties</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedFunctions.map(this.renderRow.bind(this))}
                </Table.Body>
            </Table>
        </>;
    }

    private renderRow(func: Function): ReactElement {
        return (
            <Table.Row key={func.id}>
                <Table.Cell>{func.name}</Table.Cell>
                <Table.Cell textAlign="right">
                    <Icon name="edit"
                          link
                          onClick={() => this.openEditor(func)}/>
                    <Icon name="trash"
                          link
                          color="red"
                          onClick={() => this.delete(func.id, func.name)}/>
                </Table.Cell>
            </Table.Row>
        );
    }

    private openEditor(func: Function | null) {
        const tabs = func ? this.allowEditTabs() : [{ view: EditFunction }];
        this.props.onOpenSubView(tabs, Types[Type.Function], { data: func });
    }

    protected async processDelete(functionId: FunctionId): Promise<void> {
        return this.props.services.functions.deleteFunction(functionId).then(() =>
            deleteSnapshotData(functionId, this.props.snapshot.functions, this.props.snapshot.functionMap));
    }

}
