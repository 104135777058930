import React from 'react';
import { Divider } from 'semantic-ui-react';
import { Group } from '../../../../models/core';
import { Services } from '../../../../models/services';
import { Snapshot } from '../../../../models/snapshot';
import RichEditor from '../../../shared/rich-editor';
import Sidebar from './sidebar';

export default class SidebarGroup extends Sidebar<Group> {

    protected renderContent(group: Group, snapshot: Snapshot, services: Services): React.ReactElement | null {
        return <>
            <Divider/>
            <RichEditor initialMarkdown={group.description}
                        placeholder="Geen omschrijving gevonden"
                        readonly/>
        </>;
    }
}
