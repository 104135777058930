import { GraphNode } from '../components/app/graph-page/graph-page-view';

let randomIdCount = 0;

export function generateRandomId(): string {
    const randomId = randomIdCount.toString();
    ++randomIdCount;
    return randomId;
}

export function findNodeId(node: GraphNode) {
    // Use the id of the underlying data, or if not found the id of the node itself.
    // Then node id might not always correlate to the data id (such as duplicated person nodes in the group graph)
    return node.data?.id ?? node.id;
}
