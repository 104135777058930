import { PersonId } from '../models/aliases';
import { Person } from '../models/core';
import ApiService from './api-service';

export default class PersonService extends ApiService {
    async getPersons(date: Date | null): Promise<Person[]> {
        const path = `/persons/`;
        return await this.get(path, date ?? undefined);
    }

    async addPerson(person: Person): Promise<Person> {
        const path = `/persons`;
        const nullPerson = this.ensureNoTempIds(person)[0];
        return await this.post(path, nullPerson);
    }

    async updatePerson(personId: PersonId, person: Person): Promise<Person> {
        const path = `/persons/${personId}`;
        const nullPerson = this.ensureNoTempIds(person)[0];
        return await this.patch(path, nullPerson);
    }

    async deletePerson(personId: PersonId): Promise<void> {
        const path = `/persons/${personId}`;
        return await this.delete(path);
    }
}
