import React, { PureComponent } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Branding } from '../../helpers/colors.helper';
import { Services } from '../../models/services';
import { Snapshot } from '../../models/snapshot';
import { User } from '../../models/user';
import PageMessage from '../shared/page-message';
import { onChangeDate } from './app-container';
import GraphPage from './graph-page';

export type AppViewProps = {
    user: User | null;
    services: Services | null,
    snapshot: Snapshot | null,
    onChangeDate: onChangeDate,
    error: Error | null;
};

export default class AppView extends PureComponent<AppViewProps> {
    render() {
        if (this.props.error) {
            return <PageMessage content={this.props.error?.message}
                                colour={Branding.Error}/>;
        } else if (this.isLoading()) {
            return <PageMessage content="Bezig met het ophalen en verwerken van gegevens..."/>;
        }

        return (
            <Router>
                <Route path="/:nodeType?/:nodeName?/:nodeId?"
                       render={_ => (<GraphPage
                           user={this.props.user!!}
                           services={this.props.services!!}
                           snapshot={this.props.snapshot!!}
                           onChangeDate={this.props.onChangeDate}/>)}
                />
            </Router>
        );
    }

    private isLoading(): boolean {
        return !this.props.user || !this.props.services || !this.props.snapshot;
    }

}
