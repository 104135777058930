import React, { ReactNode } from 'react';
import { Divider } from 'semantic-ui-react';
import { personToFullName } from '../../../../helpers/person.helper';
import { Function } from '../../../../models/core';
import { Services } from '../../../../models/services';
import { Snapshot } from '../../../../models/snapshot';
import { Type } from '../../../../models/type';
import RichEditor from '../../../shared/rich-editor';
import TypeLink from '../../../shared/sidebar-link/type-link';
import Sidebar from './sidebar';

export default class SidebarFunction extends Sidebar<Function> {

    protected renderContent(func: Function, snapshot: Snapshot, services: Services): React.ReactElement | null {
        return <>
            <Divider horizontal
                     content="Omschrijving"/>
            <RichEditor initialMarkdown={func.description}
                        placeholder="Geen omschrijving"
                        readonly/>
            {this.renderPersons()}
        </>;
    }

    private renderPersons(): ReactNode {
        const func = this.props.data;
        const persons = this.props.snapshot.persons.filter(person => person.function === func.id);

        const content = persons.length === 0 ?
            <p className="tw-text-neutral"><i>Deze functie heeft nog geen medewerkers.</i></p> :
            persons.map(person => {
                return <p key={person.id}>
                    <TypeLink name={personToFullName(person)}
                              type={Type.Person}
                              id={person.id}/>
                </p>;
            });

        return <>
            <Divider horizontal
                     content="Medewerkers"/>
            {content}
        </>;
    }

}
