import { PersonId } from '../models/aliases';
import { Experience } from '../models/core';
import ApiService from './api-service';

export default class ExperienceService extends ApiService {

    async getExperiencesForPerson(personId: PersonId, date: Date | null): Promise<Experience[]> {
        const path = `/experiences/${personId}`;
        return await this.get(path, date ?? undefined);
    }
}
