import React, { ReactElement } from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import { TeamId } from '../../../../models/aliases';
import { Team } from '../../../../models/core';
import { deleteSnapshotData } from '../../../../models/snapshot';
import { Type, Types } from '../../../../models/type';
import BaseViewAdmin from '../base-view-admin';
import EditTeam from '../edit-modals/edit-team';

export default class AdminTeams extends BaseViewAdmin {

    protected initialiseState(): {} {
        return {};
    }

    protected getTitle(): string {
        return 'Teams beheren';
    }

    protected renderContent(): ReactElement {
        const sortedTeams = this.props.snapshot.teams.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
        return <>
            <Button content="Team aanmaken"
                    className="tw-bg-teal tw-text-white hover:tw-bg-opacity-75"
                    onClick={_ => this.openEditor(null)}/>
            <Table basic="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Naam</Table.HeaderCell>
                        <Table.HeaderCell>Groep</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Acties</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedTeams.map(this.renderTeam.bind(this))}
                </Table.Body>
            </Table>
        </>;
    }

    private renderTeam(team: Team): ReactElement {
        const groupMap = this.props.snapshot.groupMap;
        const group = groupMap.get(team.group)!!;
        return (
            <Table.Row key={team.id}>
                <Table.Cell>{team.name}</Table.Cell>
                <Table.Cell>{group?.name}</Table.Cell>
                <Table.Cell textAlign="right">
                    <Icon name="edit"
                          link
                          onClick={() => this.openEditor(team)}/>
                    <Icon name="trash"
                          link
                          color="red"
                          onClick={() => this.delete(team.id, team.name)}/>
                </Table.Cell>
            </Table.Row>
        );
    }

    private openEditor(team: Team | null) {
        const tabs = team ? this.allowEditTabs() : [{ view: EditTeam }];
        this.props.onOpenSubView(tabs, Types[Type.Team], { data: team });
    }

    protected async processDelete(teamId: TeamId): Promise<void> {
        return this.props.services.teams.deleteTeam(teamId).then(() =>
            deleteSnapshotData(teamId, this.props.snapshot.teams, this.props.snapshot.teamMap));
    }

}
