import React, {ReactElement} from 'react';
import {Field, Form, Formik, FormikProps, FormikValues} from "formik";
import * as Yup from "yup";
import {DropdownItemProps, FormDropdown, FormInput} from "semantic-ui-react";
import {Innovation} from "../../../../models/core";
import {generateTempId} from "../../../../helpers/global.helper";
import ErrorLabel from "../../../shared/error-label";
import RichEditor from "../../../shared/rich-editor";
import {updateSnapshotData} from "../../../../models/snapshot";
import BaseViewEdit from "../base-view-edit";
import {findSuperCategories} from "../../../../helpers/innovation.helper";

export default class EditInnovation extends BaseViewEdit<Innovation> {

    private formRef = React.createRef<FormikProps<any>>();

    protected initialiseState(): {} {
        return {};
    }

    protected getTitle(): string {
        const innovation = this.props.data;
        return innovation ? `${innovation.name}` : 'Nieuwe innovatie aanmaken';
    }

    protected renderContent(): ReactElement {
        const innovation = this.props.data;
        return <>
            <Formik
                initialValues={{
                    name: innovation?.name ?? '',
                    description: innovation?.description ?? null,
                    category: innovation?.category ?? null
                }}
                validationSchema={Yup.object({
                    name: Yup.string().required("Naam is vereist").max(60, "Maximale lengte 60"),
                    description: Yup.string().nullable().min(10, "Minimale lengte 10").max(1000, "Maximale lengte 1000"),
                    category: Yup.string().nullable().required("Categorie is vereist"),
                })}
                onSubmit={(values: FormikValues) => {
                    const innovation = {
                        ...(this.props.data ?? {
                            id: generateTempId()
                        }), ...values
                    } as Innovation;
                    this.save(innovation)
                }}
                innerRef={this.formRef}>
                {({errors, setFieldTouched, setFieldValue, values}) =>
                    <Form>
                        <Field
                            name="name"
                            as={FormInput}
                            fluid
                            label="Naam"
                            placeholder="Naam"
                        />
                        <ErrorLabel content={errors.name as string}/>
                        <label>Omschrijving</label>
                        <Field
                            name="description"
                            as={RichEditor}
                            fluid
                            initialMarkdown={values.description}
                            placeholder="Omschrijving"
                            onChange={(markdown: string) => setFieldValue("description", markdown)}
                            onBlur={() => setFieldTouched("description", true)}
                        />
                        <ErrorLabel content={errors.description as string}/>
                        <Field
                            name="category"
                            as={FormDropdown}
                            selection
                            search
                            options={this.categoryOptions()}
                            fluid
                            label="Categorie"
                            placeholder="Geen"
                            onBlur={(e: any, {name}: any) => setFieldTouched(name, true)}
                            onChange={(e: any, {name, value}: any) => setFieldValue(name, value)}
                        />
                        <ErrorLabel content={errors.category as string}/>
                    </Form>
                }
            </Formik>
        </>
    }

    private categoryOptions(): DropdownItemProps[] {
        return this.props.snapshot.categories.map<DropdownItemProps>(category => {
            const categoryMap = this.props.snapshot.categoryMap;
            const superCategories = findSuperCategories(category.superCategory, categoryMap)
                .map(it => it.name).join(' < ');

            return {
                text: category.name,
                value: category.id,
                description: superCategories.length ? `< ${superCategories}` : '-'
            };
        });
    }

    protected onSubmit() {
        this.formRef.current?.handleSubmit();
    }

    protected async processAdd(innovation: Innovation): Promise<void> {
        return this.props.services.innovations.addInnovation(innovation).then((innovation: Innovation) =>
            updateSnapshotData(innovation, this.props.snapshot.innovations, this.props.snapshot.innovationMap));
    }

    protected async processUpdate(innovation: Innovation): Promise<void> {
        return this.props.services.innovations.updateInnovation(innovation.id, innovation).then((innovation: Innovation) =>
            updateSnapshotData(innovation, this.props.snapshot.innovations, this.props.snapshot.innovationMap));
    }

}
