import { CategoryMap, FunctionMap, GroupMap, InnovationMap, PersonMap, TeamMap } from './aliases';
import { Category, Function, Group, Innovation, Person, Team } from './core';

export type Snapshot = {
    date: Date | null;

    groups: Group[];
    groupMap: GroupMap;

    teams: Team[];
    teamMap: TeamMap;

    persons: Person[];
    personMap: PersonMap;

    functions: Function[];
    functionMap: FunctionMap;

    categories: Category[];
    categoryMap: CategoryMap;

    innovations: Innovation[];
    innovationMap: InnovationMap;
};

/**
 * Helper for updating or adding data to the snapshot
 * @param value the data to add or update
 * @param list e.g. "teams" in the snapshot
 * @param map e.g. "teamMap" in the snapshot
 */
export function updateSnapshotData<T extends { id: string }>(value: T, list: T[], map: Map<string, T>) {
    map.set(value.id, value);
    const index = list.findIndex(it => it.id === value.id);
    if (index === -1) {
        list.push(value);
    } else {
        list[index] = value;
    }
}

/**
 * Helper for deleting data from the snapshot
 * @param id id the data to be removed
 * @param list e.g. "teams" in the snapshot
 * @param map e.g. "teamMap" in the snapshot
 */
export function deleteSnapshotData<T extends { id: string }>(id: string, list: T[], map: Map<string, T>) {
    map.delete(id);
    const index = list.findIndex(it => it.id === id);
    if (index !== -1) {
        list.splice(index, 1);
    }
}
