import React, { Component } from 'react';
import { Button, Dropdown, DropdownItemProps, Modal } from 'semantic-ui-react';

export type onCloseFinder = (value: any | null) => void;

type Props = {
    title: string;
    selectButtonTitle: string;

    placeholder?: string;
    options: DropdownItemProps[];

    open: boolean;

    onCancel: () => void;
    onConfirm: (value: any) => any;
};

export default class DropdownDialog extends Component<Props> {

    private selectedValue: any;

    render() {
        return <Modal closeIcon
                      onClose={_ => this.props.onCancel()}
                      open={this.props.open}>
            <Modal.Header>{this.props.title}</Modal.Header>
            <Modal.Content>
                <Dropdown
                    placeholder={this.props.placeholder}
                    search
                    selection
                    fluid
                    options={this.props.options}
                    onChange={(e, prop) => {
                        this.selectedValue = prop.value;
                    }}/>
            </Modal.Content>
            <Modal.Actions>
                <Button key="cancel"
                        content="Annuleren"
                        className="tw-w-40 tw-mt-2"
                        onClick={_ => this.props.onCancel()}/>
                <Button key="select"
                        content={this.props.selectButtonTitle}
                        className="tw-w-40 tw-bg-teal tw-text-white hover:tw-bg-opacity-75"
                        onClick={_ => this.props.onConfirm(this.selectedValue)}/>
            </Modal.Actions>
        </Modal>;
    }
};
