import * as d3 from 'd3';
import React, { PureComponent, ReactElement } from 'react';
import ForceGraph, { LinkObject, NodeObject } from 'react-force-graph-2d';
import { Branding } from '../../../helpers/colors.helper';
import { setWindowTitle } from '../../../helpers/global.helper';
import { findNodeId } from '../../../helpers/graph.helper';
import { Id } from '../../../models/aliases';
import { Services } from '../../../models/services';
import { Snapshot } from '../../../models/snapshot';
import { Type, Types } from '../../../models/type';
import { User } from '../../../models/user';
import GraphUI from '../../shared/graph-ui/graph-ui';
import TabbedModal, { TabbedModalProps } from '../../shared/modals/tabbed-modal';
import PageMessage from '../../shared/page-message';
import { onChangeDate } from '../app-container';
import { onNavigateToData, onOpenView, ViewStackEntry } from './graph-page-container';
import { SIDEBAR_WIDTH } from './sidebar/sidebar';

// Dynamically created to cache draw information in order to speed up drawing
type NodeDrawCache = {
    boxX: number;
    boxY: number;
    boxWidth: number;
    boxHeight: number;
    textFont: string;
    textWidth: number;
    textHeight: number;
    textHeightOffset: number;
};

export type GraphNode = {
    id: Id;
    type: Type;
    name: string;
    /**
     * Underlying type data that supports this node. Can be undefined for dummy nodes
     */
    data?: { id: string };
    /**
     * Color of an optional blip to be displayed besides the node
     */
    blipColor?: string;
    drawCache?: NodeDrawCache;
} & NodeObject;

export type GraphLink = {
    name?: string;
    type: Type;
    force: number;
} & LinkObject;

export type GraphData = {
    nodes: GraphNode[];
    links: GraphLink[];
    /**
     * Default selected node if no specific node was centered upon building the graph
     */
    defaultNode: GraphNode | null;
}

export type Props = {
    snapshot: Snapshot;
    services: Services;
    user: User;
    graphData: GraphData | null;
    graphDataTimestamp: number;
    selectedNode: GraphNode | null;
    centeredNode: GraphNode | null;
    onNodeClick: (node: GraphNode) => void;
    onNodeDoubleClick: (node: GraphNode) => void;
    onNavigateToData: onNavigateToData;
    onChangeDate: onChangeDate;
    openViews: Array<ViewStackEntry>;
    onOpenView: onOpenView;
    onCloseView: () => void;
    loading: boolean;
    error: Error | null;
};

export default class GraphPageView extends PureComponent<Props> {

    private readonly graphRef: any = React.createRef();

    // Max and min zoom levels
    private readonly ZOOM_MAX = 20;
    private readonly ZOOM_MIN = 0.5;

    // Milliseconds for the zoom animation
    private readonly ZOOM_TIME = 100;

    // Used to prevent the graph from jumping too much upon creation
    private readonly WARMUP_TICKS = 0;

    // Force linked nodes repel each other
    private readonly FORCE_CHARGE = -60;

    // Minimum distance between all nodes
    private readonly NODE_RADIUS = 12.0;

    // Gets multiplied by the node type size to determine the actual radius
    private readonly NODE_BASE_COLLISION_RADIUS = 2.0;

    // How much inertia nodes have
    private readonly VELOCITY_DECAY = 0.2;

    // How quickly the simulation cools down
    private readonly ALPHA_DECAY = 0.02;

    private readonly DOUBLE_CLICK_TIME = 500;

    // Ratio of the icon size to the node height
    private readonly ICON_SIZE = 0.75;

    // Ratio of the blip size to the node height
    private readonly BLIP_SIZE = 0.35;

    // Size of the padding between the node label and node edge
    private readonly PADDING = 0.4;

    // Size of the outline around the node box
    private readonly OUTLINE = 0.3;
    private readonly OUTLINE2 = this.OUTLINE + this.OUTLINE;

    private lastClickTime: number = 0;
    private lastClickTarget: GraphNode | null = null;
    private zoom = 1.0;
    private selectedNodeDuplicates = new Array<GraphNode>();

    render() {
        setWindowTitle(this.props.centeredNode?.name);

        const snapshotTimestamp = this.props.snapshot.date?.getTime() ?? 0;
        if (this.props.loading || !this.props.graphData || snapshotTimestamp !== this.props.graphDataTimestamp) {
            return <PageMessage content="Bezig met het ophalen en verwerken van gegevens..."/>;
        } else if (this.props.error) {
            return <PageMessage content={this.props.error?.message}
                                colour={Branding.Error}/>;
        }

        if (!this.props.graphData.nodes.some(node => node.type !== Type.Dummy)) {
            return <>
                {this.renderGraphUI()}
                <PageMessage content="Geen data om te tonen"/>
            </>;
        }
        return <div className="tw-flex tw-items-stretch tw-flex-nowrap">
            {this.renderModal()}
            {this.renderSidebar()}
            <div className="tw-w-full tw-relative tw-overflow-hidden"
                 onMouseLeave={() => document.body.style.cursor = 'auto'}>
                <ForceGraph
                    width={window.innerWidth - SIDEBAR_WIDTH}
                    height={window.innerHeight}
                    graphData={this.props.graphData}
                    onNodeHover={(node => document.body.style.cursor = node ? 'pointer' : 'auto')}
                    onNodeClick={(node => this.handleNodeClick(node as GraphNode))}
                    nodeRelSize={this.NODE_RADIUS}
                    linkWidth={1}
                    warmupTicks={this.WARMUP_TICKS}
                    onRenderFramePre={this.beforeFrameRender.bind(this)}
                    nodeCanvasObject={this.renderNode.bind(this)}
                    linkColor={(node => Types[(node as GraphNode).type].color)}
                    d3VelocityDecay={this.VELOCITY_DECAY}
                    d3AlphaDecay={this.ALPHA_DECAY}
                    onZoom={transform => {
                        this.zoom = transform.k;
                        transform.k = 1;
                    }}
                    ref={this.graphRef}
                />
                {this.renderGraphUI()}
            </div>
        </div>;
    }

    componentDidMount() {
        this.initForceGraphProperties();
    }

    componentDidUpdate() {
        this.initForceGraphProperties();
    }

    private initForceGraphProperties() {
        const forceGraph = this.graphRef.current;
        if (forceGraph) {
            forceGraph.d3Force('charge').strength(this.FORCE_CHARGE);
            forceGraph.d3Force('link').strength((link: GraphLink) => link.force);
            forceGraph.d3Force('collide', d3.forceCollide().radius((d3Node) => {
                const node = d3Node as GraphNode;
                return this.NODE_BASE_COLLISION_RADIUS * Types[node.type].size;
            }));
            forceGraph.zoom(this.zoom);
        }

        const selectedNode = this.props.selectedNode;
        this.selectedNodeDuplicates = [];
        if (selectedNode && this.props.graphData) {
            const selectedNodeId = findNodeId(selectedNode);
            this.props.graphData.nodes.forEach(node => {
                const isDuplicate = findNodeId(node) === selectedNodeId
                    && node !== selectedNode;
                if (isDuplicate) {
                    this.selectedNodeDuplicates.push(node);
                }
            });
        }
    }

    private beforeFrameRender(ctx: CanvasRenderingContext2D, globalScale: number) {
        ctx.lineDashOffset = Date.now() / 100 % -1000;
        ctx.lineCap = 'square';
        ctx.lineJoin = 'miter';

        const selectedNode = this.props.selectedNode;
        if (selectedNode) {
            ctx.beginPath();
            ctx.lineWidth = 1.5 / globalScale;
            ctx.strokeStyle = Branding.Neutral;
            ctx.setLineDash([4 / globalScale, 6 / globalScale]);
            this.selectedNodeDuplicates.forEach(node => {
                ctx.moveTo(selectedNode.x!!, selectedNode.y!!);
                ctx.lineTo(node.x!!, node.y!!);
            });
            ctx.stroke();
        }

        // Drawing selected node
        ctx.setLineDash([1, 1.5]);
        ctx.lineWidth = 1;

        // Node labels
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
    }

    private renderNode(d3Node: NodeObject, ctx: CanvasRenderingContext2D) {
        const node = d3Node as GraphNode;
        const label = node.name;
        const typeInfo = Types[node.type];
        const size = typeInfo.size;

        const cache = node.drawCache ?? this.createNodeDrawCache(node, ctx);
        if (!node.drawCache) {
            node.drawCache = cache;
        }

        ctx.font = cache.textFont;

        const selectedNode = this.props.selectedNode;
        const isSelected = node === selectedNode || (selectedNode && findNodeId(node) === findNodeId(selectedNode));
        const isCentered = node.id === this.props.centeredNode?.id;

        const color = isCentered ? typeInfo.backgroundColor!! : typeInfo.color;
        const backgroundColor = isCentered ? typeInfo.color : typeInfo.backgroundColor!!;
        const outlineColor = typeInfo.color;

        const x = node.x!! + cache.boxX;
        const y = node.y!! + cache.boxY;
        const padding = this.PADDING * size;

        // Outline
        ctx.fillStyle = color;
        ctx.strokeStyle = outlineColor;
        isSelected
            ? ctx.strokeRect(
            x - this.OUTLINE,
            y - this.OUTLINE,
            cache.boxWidth + this.OUTLINE2,
            cache.boxHeight + this.OUTLINE2
            )
            : ctx.fillRect(
            x - this.OUTLINE,
            y - this.OUTLINE,
            cache.boxWidth + this.OUTLINE2,
            cache.boxHeight + this.OUTLINE2
            );

        // Background
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(x, y, cache.boxWidth, cache.boxHeight);

        // Icon
        if (typeInfo.icon) {
            const iconSize = size * this.ICON_SIZE;
            ctx.drawImage(typeInfo.icon, x + padding, node.y!! - iconSize / 2, iconSize, iconSize);
        }

        // Blip
        if (node.blipColor) {
            const blipSize = size * this.BLIP_SIZE;
            ctx.fillStyle = node.blipColor;
            ctx.beginPath();
            ctx.arc(x + cache.boxWidth - blipSize - padding, node.y!!, blipSize, 0, Math.PI * 2);
            ctx.fill();
        }

        // Text
        ctx.fillStyle = color;
        ctx.fillText(label, node.x!!, node.y!! + cache.textHeightOffset);
    }

    private createNodeDrawCache(node: GraphNode, ctx: CanvasRenderingContext2D): NodeDrawCache {
        const typeInfo = Types[node.type];
        const size = typeInfo.size;

        const textFont = size + 'px Poppins';
        ctx.font = textFont;

        const textMeasure = ctx.measureText(node.name);
        const textWidth = Math.abs(textMeasure.actualBoundingBoxLeft) + Math.abs(textMeasure.actualBoundingBoxRight);
        const textHeight = Math.abs(textMeasure.actualBoundingBoxAscent) + Math.abs(textMeasure.actualBoundingBoxDescent);
        const textHeightOffset = (Math.abs(textMeasure.actualBoundingBoxAscent) - Math.abs(textMeasure.actualBoundingBoxDescent)) / 2;

        const padding = this.PADDING * size;

        let boxX = -textWidth / 2 - padding;
        const boxY = -textHeight / 2 - padding;
        let boxWidth = textWidth + padding * 2;
        const boxHeight = textHeight + padding * 2;

        const extraWidth = this.ICON_SIZE * size + padding;
        if (typeInfo.icon) {
            boxWidth += extraWidth;
            boxX -= extraWidth;
        }
        if (node.blipColor) {
            boxWidth += extraWidth;
        }

        return {
            boxX, boxY, boxWidth, boxHeight,
            textFont, textWidth, textHeight, textHeightOffset
        };
    }

    private handleZoom(zoomFactor: number) {
        let targetZoom = this.zoom * zoomFactor;
        targetZoom = Math.max(Math.min(targetZoom, this.ZOOM_MAX), this.ZOOM_MIN);
        this.graphRef?.current.zoom(targetZoom, this.ZOOM_TIME);
    }

    private handleNodeClick(node: GraphNode) {
        const now = Date.now();
        if (now - this.lastClickTime < this.DOUBLE_CLICK_TIME && node === this.lastClickTarget) {
            this.lastClickTime = 0;
            this.lastClickTarget = null;
            this.props.onNodeDoubleClick(node);
        } else {
            this.lastClickTarget = node;
            this.lastClickTime = now;
            this.props.onNodeClick(node);
        }
    }

    private renderGraphUI(): ReactElement {
        return <GraphUI snapshot={this.props.snapshot}
                        user={this.props.user}
                        activeGraphType={this.props.centeredNode?.type ?? null}
                        onOpenAdminModal={this.props.onOpenView}
                        onChangeDate={this.props.onChangeDate}
                        onZoom={this.handleZoom.bind(this)}
                        onNavigateToData={this.props.onNavigateToData}/>;
    }

    private renderSidebar() {
        const node = this.props.selectedNode;
        if (!node) {
            return null;
        }

        const typeConfig = Types[node.type];

        return React.createElement(typeConfig.sidebar as any, {
            data: node.data,
            typeConfig,
            title: node.name,
            snapshot: this.props.snapshot,
            services: this.props.services,
            user: this.props.user,
            blipColor: node.blipColor,
            onNavigateToData: this.props.onNavigateToData,
            onOpenView: this.props.onOpenView
        });
    }

    private renderModal() {
        const openModals = this.props.openViews;
        if (openModals.length === 0) {
            return null;
        }

        const modalEntry = openModals[openModals.length - 1];

        return React.createElement(TabbedModal, {
            tabs: modalEntry.tabs,
            viewProps: {
                snapshot: this.props.snapshot,
                services: this.props.services,
                user: this.props.user,
                typeConfig: modalEntry.typeConfig,
                onClose: this.props.onCloseView,
                onOpenSubView: this.props.onOpenView,
                ...modalEntry.props
            }
        } as TabbedModalProps);
    }

}
