import assert from 'assert';
import React, { ReactElement } from 'react';
import { Button, Confirm, DropdownItemProps, Icon, Input, Table } from 'semantic-ui-react';
import { deepCopy, generateTempId, newError } from '../../../../helpers/global.helper';
import { PersonId, RoleId } from '../../../../models/aliases';
import { Role, Team } from '../../../../models/core';
import DropdownDialog from '../../dropdown-dialog/dropdown-dialog';
import RichEditor from '../../rich-editor';
import BaseViewEdit from '../base-view-edit';


enum UiAction {
    NOTHING,
    ADDING_MEMBER
}


type State = {
    action: UiAction;
    team: Team;
    confirmDialog: string | null;
    onConfirm: (() => void) | null;
    addMemberOptions: DropdownItemProps[];
    onAddMember: ((personId: PersonId) => void) | null;
};

export default class EditTeamMembers extends BaseViewEdit<Team, State> {

    protected initialiseState(): State {
        return {
            action: UiAction.NOTHING,
            team: deepCopy(this.props.data!!),
            confirmDialog: null,
            onConfirm: null,
            addMemberOptions: [],
            onAddMember: null
        };
    }

    protected async onMount() {

    }

    protected getTitle(): string {
        const team = this.props.data!!;
        return `${team.name} - Leden`;
    }

    protected renderContent(): ReactElement {
        return <>
            <Button
                content={<><Icon name="add"
                                 fitted/> Rol</>}
                className="tw-mr-2 tw-bg-teal tw-text-white tw-w-32 hover:tw-bg-opacity-75"
                onClick={() => this.addRole()}/>
            {this.renderRoles()}
            <Confirm open={this.state.confirmDialog !== null}
                     content={this.state.confirmDialog}
                     onCancel={() => this.setState({ confirmDialog: null })}
                     onConfirm={() => {
                         this.setState({ confirmDialog: null });
                         this.state.onConfirm?.();
                     }}/>
            <DropdownDialog title="Teamlid toevoegen"
                            selectButtonTitle="Toevoegen"
                            open={this.state.action === UiAction.ADDING_MEMBER}
                            options={this.state.addMemberOptions}
                            onCancel={() => this.setState({ action: UiAction.NOTHING })}
                            onConfirm={(personId: PersonId) => {
                                this.setState({ action: UiAction.NOTHING });
                                this.state.onAddMember?.(personId);
                            }}/>
        </>;
    }

    private renderRoles(): ReactElement[] {
        const team = this.state.team;

        return team.roles.sort((a, b) => a.name.localeCompare(b.name))
            .map(role => (
                <Table key={role.id}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width="5">
                                <Input defaultValue={role.name}
                                       error={this.containsError(role)}
                                       placeholder="Rolnaam"
                                       fluid
                                       onChange={(e) => role.name = e.currentTarget.value}
                                       onBlur={() => this.setState({})}/>
                            </Table.HeaderCell>
                            <Table.HeaderCell colSpan="2"
                                              width="10">
                                <RichEditor initialMarkdown={role.description}
                                            placeholder="Geen omschrijving"
                                            onChange={markdown => role.description = markdown}/>
                            </Table.HeaderCell>
                            <Table.HeaderCell width="1"
                                              textAlign="center">
                                <Icon name="add user"
                                      link
                                      color="green"
                                      onClick={() => this.setState({
                                          action: UiAction.ADDING_MEMBER,
                                          addMemberOptions: this.addMemberOptions(role),
                                          onAddMember: (personId: PersonId) => this.addMember(role, personId)
                                      })}/>
                                <Icon name="trash"
                                      link
                                      color="red"
                                      onClick={() => this.setState({
                                          confirmDialog: `Weet je zeker dat je de rol "${role.name}" wilt verwijderen?\nDeze rol heeft ${role.members.length} leden.`,
                                          onConfirm: () => this.removeRole(role.id)
                                      })}/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.renderRoleMembers(role)}
                    </Table.Body>
                </Table>
            ));
    }

    private renderRoleMembers(role: Role): ReactElement[] {
        return role.members.map(personId => {
            const person = this.props.snapshot.personMap.get(personId)!!;
            const func = this.props.snapshot.functionMap.get(person.function)!!;
            const displayName = [person.name, person.prefix, person.surname].join(' ');
            return (
                <Table.Row key={person.id}>
                    <Table.Cell width="5">{displayName}</Table.Cell>
                    <Table.Cell width="5">{person.email}</Table.Cell>
                    <Table.Cell width="5">{func.name}</Table.Cell>
                    <Table.Cell width="1"
                                textAlign="center">
                        <Icon name="remove user"
                              link
                              color="red"
                              onClick={() => this.setState({
                                  confirmDialog: `Weet je zeker dat je het teamlid "${displayName}" wilt verwijderen uit de rol "${role.name}"?`,
                                  onConfirm: () => this.removeMember(role, person.id)
                              })}/>
                    </Table.Cell>
                </Table.Row>);
        });
    }

    private addMemberOptions(forRole: Role): DropdownItemProps[] {
        const roleMembers = new Set(forRole.members);
        const options = this.props.snapshot.persons
            .filter(person => !roleMembers.has(person.id))
            .map(person => {
                return {
                    value: person.id,
                    text: [person.name, person.prefix, person.surname].join(' '),
                    title: person.email,
                    description: this.props.snapshot.functionMap.get(person.function)!!.name
                };
            });

        return options.sort((a, b) => {
            return a.description.localeCompare(b.description) * 2 + a.text.localeCompare(b.text);
        });
    }

    private addRole() {
        const team = this.state.team;
        team.roles.push({
            id: generateTempId(),
            members: [],
            name: '',
            description: null
        });
        this.setState({ team });
    }

    private removeRole(roleId: RoleId) {
        const team = this.state.team;
        team.roles = team.roles.filter(it => it.id !== roleId);
    }

    private addMember(role: Role, personId: PersonId) {
        role.members.push(personId);
    }

    private removeMember(role: Role, personId: PersonId) {
        role.members = role.members.filter(it => it !== personId);
    }

    protected onSubmit() {
        const anyErrors = this.state.team.roles.some(this.containsError);
        if (anyErrors) {
            this.setState({ error: newError('Error', 'Sommige velden bevatten nog fouten!') });
        } else {
            this.setState({ error: null });
            this.save(this.state.team);
        }
    }

    protected async processAdd(team: Team): Promise<void> {
        assert(false, 'Members should never be editable during team creation');
    }

    protected async processUpdate(team: Team): Promise<void> {
        return this.props.services.teams.updateTeamRoles(team.id, team.roles).then(roles => {
            this.props.snapshot.teamMap.get(team.id)!!.roles = roles;
        });
    }

    private containsError(role: Role): boolean {
        return role.name.length === 0;
    }
}
