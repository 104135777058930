import React, { Component } from 'react';
import { Search, SearchResultProps } from 'semantic-ui-react';
import { SearchProps, SearchResultData } from 'semantic-ui-react/dist/commonjs/modules/Search/Search';
import { Category, Function, Group, Innovation, Person, Team } from '../../../models/core';
import { Snapshot } from '../../../models/snapshot';
import { Type, Types } from '../../../models/type';
import { onNavigateToData } from '../../app/graph-page/graph-page-container';

type Props = {
    snapshot: Snapshot;
    onNavigateToData: onNavigateToData;
};

export type Findable = {
    searchString: string;
    type: Type;
    name: string;
    id: string;
    data: any;
};

type State = {
    results: SearchResultProps[]
    findables: Findable[]
};

export default class Finder extends Component<Props, State> {

    readonly state = this.initialiseState();

    private initialiseState(): State {
        const snapshot = this.props.snapshot;
        const findables = new Array<Findable>();

        this.addFindables<Person>(findables, Type.Person, snapshot.persons,
            it => [it.name, it.prefix, it.surname].join(' '),
            it => [it.name, it.prefix, it.surname, it.email, it.phone, it.address].join(' ').toLowerCase()
        );

        this.addFindables<Team>(findables, Type.Team, snapshot.teams,
            it => it.name,
            it => it.name.toLowerCase()
        );

        this.addFindables<Group>(findables, Type.Group, snapshot.groups,
            it => it.name,
            it => it.name.toLowerCase()
        );

        this.addFindables<Function>(findables, Type.Function, snapshot.functions,
            it => [it.name].join(' '),
            it => it.name.toLowerCase()
        );

        this.addFindables<Innovation>(findables, Type.Innovation, snapshot.innovations,
            it => [it.name].join(' '),
            it => it.name.toLowerCase()
        );

        this.addFindables<Category>(findables, Type.Category, snapshot.categories,
            it => [it.name].join(' '),
            it => it.name.toLowerCase()
        );

        return {
            results: [],
            findables
        };
    }

    render() {
        return (
            <Search
                icon="search"
                placeholder="Zoeken"
                noResultsMessage="Geen resultaten"
                results={this.state.results}
                fluid
                minCharacters={2}
                onSearchChange={this.handleSearchChange.bind(this)}
                onResultSelect={this.handleResultSelect.bind(this)}/>
        );
    }

    private addFindables<T>(
        findables: Findable[],
        type: Type,
        dataArray: T[],
        toName: (data: T) => string,
        toSearchString: (data: T) => string
    ) {
        findables.push(...dataArray.map<Findable>(it => {
            return {
                type,
                name: toName(it),
                searchString: toSearchString(it),
                id: (it as any).id!! as string,
                data: it
            };
        }));
    }

    private handleSearchChange(_event: React.MouseEvent<HTMLElement>, data: SearchProps) {
        const search = data.value ?? '';

        if (search.length === 0) {
            this.setState({ results: [] });
        }

        const sanitisedSearch = search.toLowerCase().trim();

        const findables = this.state.findables.filter(it => it.searchString.includes(sanitisedSearch));

        const results = findables.map<SearchResultProps>(findable => {
            return {
                title: findable.name,
                description: Types[findable.type].name,
                id: findable.id,
                findable
            };
        });

        this.setState({ results });
    }

    private handleResultSelect(_event: React.MouseEvent<HTMLDivElement>, data: SearchResultData) {
        const findable = data.result.findable;
        this.props.onNavigateToData(findable.type, findable.name, findable.id, findable.data);
    }

}
