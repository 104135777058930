import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import React, { ReactElement } from 'react';
import { DropdownItemProps, FormDropdown, FormInput } from 'semantic-ui-react';
import * as Yup from 'yup';
import { generateTempId } from '../../../../helpers/global.helper';
import { GroupId } from '../../../../models/aliases';
import { Group } from '../../../../models/core';
import { updateSnapshotData } from '../../../../models/snapshot';
import ErrorLabel from '../../../shared/error-label';
import RichEditor from '../../../shared/rich-editor';
import BaseViewEdit from '../base-view-edit';

export default class EditGroup extends BaseViewEdit<Group> {

    private formRef = React.createRef<FormikProps<any>>();

    protected initialiseState(): {} {
        return {};
    }

    protected getTitle(): string {
        const group = this.props.data;
        return group ? `${group.name}` : 'Nieuwe groep aanmaken';
    }

    protected renderContent(): ReactElement {
        const group = this.props.data;
        return <>
            <Formik
                initialValues={{
                    name: group?.name ?? '',
                    description: group?.description ?? null,
                    superGroup: group?.superGroup ?? null
                }}
                validationSchema={Yup.object({
                    name: Yup.string().required('Groepsnaam is vereist').max(60, 'Maximale lengte 60'),
                    description: Yup.string().nullable().min(10, 'Minimale lengte 10').max(
                        1000,
                        'Maximale lengte 1000'
                    ),
                    superGroup: Yup.string().nullable()
                })}
                onSubmit={(values: FormikValues) => {
                    const group = {
                        ...(this.props.data ?? {
                            id: generateTempId()
                        }), ...values
                    } as Group;
                    this.save(group);
                }}
                innerRef={this.formRef}>
                {({ errors, setFieldTouched, setFieldValue, values }) =>
                    <Form>
                        <Field
                            name="name"
                            as={FormInput}
                            fluid
                            label="Groepsnaam"
                            placeholder="Groepsnaam"
                        />
                        <ErrorLabel content={errors.name as string}/>
                        <label>Omschrijving</label>
                        <Field
                            name="description"
                            as={RichEditor}
                            fluid
                            initialMarkdown={values.description}
                            placeholder="Omschrijving"
                            onChange={(markdown: string) => setFieldValue('description', markdown)}
                            onBlur={() => setFieldTouched('description', true)}
                        />
                        <ErrorLabel content={errors.description as string}/>
                        <Field
                            name="superGroup"
                            as={FormDropdown}
                            selection
                            search
                            options={this.superGroupOptions(group)}
                            fluid
                            label="Supergroep"
                            placeholder="Geen"
                            onBlur={(e: any, { name }: any) => setFieldTouched(name, true)}
                            onChange={(e: any, { name, value }: any) => setFieldValue(name, value)}
                        />
                        <ErrorLabel content={errors.superGroup as string}/>
                    </Form>
                }
            </Formik>
        </>;
    }

    private superGroupOptions(baseGroup: Group | null): DropdownItemProps[] {
        let availableGroups = this.props.snapshot.groups;
        if (baseGroup) {
            // Disallow group if it is a sub-group of the base group
            const groupMap = this.props.snapshot.groupMap;
            availableGroups = availableGroups.filter(it => {
                let currentId: GroupId | null = it.id;
                do {
                    if (currentId === baseGroup.id) {
                        return false;
                    }
                    currentId = groupMap.get(currentId)!!.superGroup;
                } while (currentId !== null);
                return true;
            });
        }

        const options = availableGroups.map<DropdownItemProps>(group => {
            return {
                text: group.name,
                value: group.id
            };
        });

        options.unshift({
            content: <i>Geen</i>,
            // @ts-ignore
            value: null,
            key: 'none'
        });

        return options;
    }

    protected onSubmit() {
        this.formRef.current?.handleSubmit();
    }

    protected async processAdd(group: Group): Promise<void> {
        return this.props.services.groups.addGroup(group).then((group: Group) =>
            updateSnapshotData(group, this.props.snapshot.groups, this.props.snapshot.groupMap));
    }

    protected async processUpdate(group: Group): Promise<void> {
        return this.props.services.groups.updateGroup(group.id, group).then((group: Group) =>
            updateSnapshotData(group, this.props.snapshot.groups, this.props.snapshot.groupMap));
    }

}
