import { FunctionId } from '../models/aliases';
import { Function } from '../models/core';
import ApiService from './api-service';

export default class FunctionService extends ApiService {

    async getFunctions(date: Date | null): Promise<Function[]> {
        const path = `/functions/`;
        return await this.get(path, date ?? undefined);
    }

    async addFunction(func: Function): Promise<Function> {
        const path = `/functions`;
        const nullFunction = this.ensureNoTempIds(func)[0];
        return await this.post(path, nullFunction);
    }

    async updateFunction(functionId: FunctionId, func: Function): Promise<Function> {
        const path = `/functions/${functionId}`;
        const nullFunction = this.ensureNoTempIds(func)[0];
        return await this.patch(path, nullFunction);
    }

    async deleteFunction(functionId: FunctionId): Promise<void> {
        const path = `/functions/${functionId}`;
        return await this.delete(path);
    }
}
