import React from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { Type } from '../../../models/type';
import { onNavigateToData } from '../../app/graph-page/graph-page-container';

type Props = {
    activeGraphType: Type | null;
    className?: string;
    onNavigateToData: onNavigateToData;
};

type GraphDropdownItem = {
    name: string;
    type: Type;
}

const graphs: GraphDropdownItem[] = [
    { name: 'Organogram', type: Type.Group },
    { name: 'Functies', type: Type.Function },
    { name: 'Taxonomie', type: Type.Innovation }
];

const GraphDropdown = (props: Props) => {
    const views = availableGraphs();

    return <Dropdown
        button
        className={'icon ' + props.className}
        floating
        labeled
        value={-1}
        icon="bars"
        options={views}
        search
        text="Grafieken.."
        selectOnBlur={false}
        selectOnNavigation={false}
        onChange={((e, prop) => {
            const type = graphs[prop.value as number].type;
            props.onNavigateToData(type);
        })}/>;

    function availableGraphs(): DropdownItemProps[] {
        return graphs.map((graph, index) => {
            return {
                text: graph.name,
                value: index
            };
        });
    }

};

export default GraphDropdown;
