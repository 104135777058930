import { GraphData } from '../components/app/graph-page/graph-page-view';
import functionGraph from '../components/app/graph-page/graphs/function-graph';
import groupGraph from '../components/app/graph-page/graphs/group-graph';
import innovationGraph from '../components/app/graph-page/graphs/innovation-graph';
import personGraph from '../components/app/graph-page/graphs/person-graph';
import teamGraph from '../components/app/graph-page/graphs/team-graph';
import Sidebar from '../components/app/graph-page/sidebar/sidebar';
import SidebarCategory from '../components/app/graph-page/sidebar/sidebar-category';
import SidebarDummy from '../components/app/graph-page/sidebar/sidebar-dummy';
import SidebarExperience from '../components/app/graph-page/sidebar/sidebar-experience';
import SidebarFunction from '../components/app/graph-page/sidebar/sidebar-function';
import SidebarGroup from '../components/app/graph-page/sidebar/sidebar-group';
import SidebarInnovation from '../components/app/graph-page/sidebar/sidebar-innovation';
import SidebarPerson from '../components/app/graph-page/sidebar/sidebar-person';
import SidebarRole from '../components/app/graph-page/sidebar/sidebar-role';
import SidebarTeam from '../components/app/graph-page/sidebar/sidebar-team';
import SidebarUsage from '../components/app/graph-page/sidebar/sidebar-usage';
import AdminCategories from '../components/shared/modals/admin-modals/admin-categories';
import AdminFunctions from '../components/shared/modals/admin-modals/admin-functions';
import AdminGroups from '../components/shared/modals/admin-modals/admin-groups';
import AdminInnovations from '../components/shared/modals/admin-modals/admin-innovtions';
import AdminPersons from '../components/shared/modals/admin-modals/admin-persons';
import AdminTeams from '../components/shared/modals/admin-modals/admin-teams';
import BaseView from '../components/shared/modals/base-view';
import BaseViewAdmin from '../components/shared/modals/base-view-admin';
import EditCategory from '../components/shared/modals/edit-modals/edit-category';
import EditFunction from '../components/shared/modals/edit-modals/edit-function';
import EditGroup from '../components/shared/modals/edit-modals/edit-group';
import EditInnovation from '../components/shared/modals/edit-modals/edit-innovation';
import EditPerson from '../components/shared/modals/edit-modals/edit-person';
import EditTeam from '../components/shared/modals/edit-modals/edit-team';
import EditTeamInnovations from '../components/shared/modals/edit-modals/edit-team-innovations';
import EditTeamMembers from '../components/shared/modals/edit-modals/edit-team-members';
import { Branding, hexToRgb, lerpColors, rgbToHex } from '../helpers/colors.helper';
import innovationIcon from '../icons/innovation.webp';
import personIcon from '../icons/person.webp';
import rocketIcon from '../icons/rocket.webp';
import teamIcon from '../icons/team.webp';
import { Team } from './core';
import { Services } from './services';
import { Snapshot } from './snapshot';
import { KeycloakRoles } from './user';


export enum Type {
    // Dummies don't represent a real data type but simply a placeholder for front-end only data types.
    Dummy,
    Group,
    Team,
    Role,
    Person,
    Function,
    Category,
    Innovation,
    Usage,
    Experience
}


export enum Size {
    Large = 9,
    Medium = 7,
    Small = 5,
}


export function typeToString(type: Type): string {
    return Type[type];
}

export function typeFromString(type: string): Type | null {
    return Type[type as any] as unknown as Type ?? null;
}

type TabDescription = {
    /**
     * Reference to the implementation of the BaseView to render
     */
    view: typeof BaseView;

    /**
     * Optional name of the tab
     */
    name: string;

    /**
     * Rolles allowed to access this tab
     */
    roles: KeycloakRoles[];
}

export type AdminViewDescription = {
    /**
     * Reference to component extending BaseViewAdmin
     */
    view: typeof BaseViewAdmin;

    /**
     * Rolles allowed to access this admin view
     */
    roles: KeycloakRoles[];
}

export type TypeConfig = {

    /**
     * The localised name for displaying in the UI
     */
    name: string;

    /**
     * The default color that the node takes on in texts and in the visualisation
     */
    color: string;

    /**
     * Less intense shade of the color used for drawing the node background color.
     * Is automatically calculated. Custom values will be overridden.
     */
    backgroundColor?: string;

    /**
     * The default size of a node
     */
    size: number;

    /**
     * Graph to be created when centered on a node of this type.
     * Leave undefined when the node should not be able to be centered on.
     */
    graph?: (nodeId: string, snapshot: Snapshot, services: Services) => Promise<GraphData> | null

    /**
     * The icon to show in the graph / sidebar / legend
     */
    icon?: HTMLImageElement;

    /**
     * Reference to the sidebar that should be used to list extra information of this data.
     */
    sidebar: typeof Sidebar;

    /**
     * Views available for editing this data type
     * When using more than a single entry the views will appear as tabs when editing a type
     */
    editViews?: TabDescription[];

    /**
     * The view used to manage sets of these types, such as managing all teams within an organisation.
     */
    admin?: AdminViewDescription;
}

export const Types: { [type in Type]: TypeConfig } = {
    [Type.Dummy]: {
        name: '-',
        color: Branding.Neutral,
        size: Size.Small,
        sidebar: SidebarDummy
    },
    [Type.Group]: {
        name: 'Groep',
        color: Branding.Orange,
        icon: getIcon(rocketIcon),
        size: Size.Large,
        sidebar: SidebarGroup,
        graph: (nodeId: string, snapshot: Snapshot, services: Services) => groupGraph(snapshot),
        editViews: [
            {
                name: 'Algemeen',
                view: EditGroup,
                roles: [KeycloakRoles.OrganisationManager]
            }
        ],
        admin: {
            view: AdminGroups,
            roles: [KeycloakRoles.OrganisationManager]
        }
    },
    [Type.Team]: {
        name: 'Team',
        color: Branding.Purple,
        icon: getIcon(teamIcon),
        sidebar: SidebarTeam,
        size: Size.Medium,
        graph: (nodeId: string, snapshot: Snapshot, services: Services) => {
            const team = snapshot.teamMap.get(nodeId);
            return team ? teamGraph(team, snapshot, services) : groupGraph(snapshot);
        },
        editViews: [
            {
                name: 'Algemeen',
                view: EditTeam,
                roles: [KeycloakRoles.OrganisationManager]
            }, {
                name: 'Innovaties',
                view: EditTeamInnovations,
                roles: [KeycloakRoles.InnovationManager]
            }, {
                name: 'Leden',
                view: EditTeamMembers,
                roles: [KeycloakRoles.OrganisationManager]
            }
        ],
        admin: {
            view: AdminTeams,
            roles: [KeycloakRoles.OrganisationManager]
        }
    },
    [Type.Role]: {
        name: 'Rol',
        color: Branding.TurquoiseLight,
        size: Size.Small,
        sidebar: SidebarRole
    },
    [Type.Person]: {
        name: 'Medewerker',
        color: Branding.Teal,
        icon: getIcon(personIcon),
        size: Size.Small,
        sidebar: SidebarPerson,
        graph: (nodeId: string, snapshot: Snapshot, services: Services) => {
            const person = snapshot.personMap.get(nodeId);
            return person ? personGraph(person, snapshot, services) : null;
        },
        editViews: [
            {
                name: 'Algemeen',
                view: EditPerson,
                roles: [KeycloakRoles.OrganisationManager]
            }
        ],
        admin: {
            view: AdminPersons,
            roles: [KeycloakRoles.OrganisationManager]
        }
    },
    [Type.Function]: {
        name: 'Functie',
        color: Branding.Error,
        size: Size.Small,
        sidebar: SidebarFunction,
        graph: (nodeId: string, snapshot: Snapshot, services: Services) => {
            const func = snapshot.functionMap.get(nodeId);
            return functionGraph(func ?? null, snapshot);
        },
        editViews: [
            {
                name: 'Algemeen',
                view: EditFunction,
                roles: [KeycloakRoles.OrganisationManager]
            }
        ],
        admin: {
            view: AdminFunctions,
            roles: [KeycloakRoles.OrganisationManager]
        }
    },
    [Type.Category]: {
        name: 'Innovatie-categorie',
        color: Branding.GreenLight,
        size: Size.Small,
        sidebar: SidebarCategory,
        graph: (nodeId: string, snapshot: Snapshot, services: Services) => {
            return innovationGraph(null, snapshot, services);
        },
        editViews: [
            {
                name: 'Algemeen',
                view: EditCategory,
                roles: [KeycloakRoles.InnovationManager]
            }
        ],
        admin: {
            view: AdminCategories,
            roles: [KeycloakRoles.InnovationManager]
        }
    },
    [Type.Innovation]: {
        name: 'Innovatie',
        color: Branding.Teal,
        icon: getIcon(innovationIcon),
        size: Size.Small,
        sidebar: SidebarInnovation,
        graph: (nodeId: string, snapshot: Snapshot, services: Services) => {
            const innovation = snapshot.innovationMap.get(nodeId);
            return innovationGraph(innovation ?? null, snapshot, services);
        },
        editViews: [
            {
                name: 'Algemeen',
                view: EditInnovation,
                roles: [KeycloakRoles.InnovationManager]
            }
        ],
        admin: {
            view: AdminInnovations,
            roles: [KeycloakRoles.InnovationManager]
        }
    },
    [Type.Usage]: {
        name: 'Team-innovatie',
        color: Branding.Teal,
        icon: getIcon(innovationIcon),
        size: Size.Small,
        sidebar: SidebarUsage,
        graph: (nodeId: string, snapshot: Snapshot, services: Services) => {
            return innovationGraph(null, snapshot, services);
        }
    },
    [Type.Experience]: {
        name: 'Ervaring',
        color: Branding.Teal,
        icon: getIcon(innovationIcon),
        size: Size.Small,
        sidebar: SidebarExperience
    }
};

// Auto-calculate backgroundColor based by interpolating the color with white
Object.entries(Types).forEach(([_key, type]) => {
    const white = { r: 255, g: 255, b: 255 };
    const typeColor = hexToRgb(type.color)!!;
    const backgroundColor = lerpColors(typeColor, white, 0.8);
    type.backgroundColor = rgbToHex(backgroundColor);
});

function getIcon(source: string) {
    const image = new Image();
    image.src = source;
    return image;
}
