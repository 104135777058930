import 'draft-js/dist/Draft.css';
import React from 'react';
import ReactDOM from 'react-dom';

import 'semantic-ui-css/semantic.min.css';
import App from './components/app';
import './stylesheets/base.css';

const root = document.getElementById('root');

const app = React.createElement(App, {});

ReactDOM.render(app, root);
