import React, { ReactElement } from 'react';
import { Divider } from 'semantic-ui-react';
import { personToFullName } from '../../../../helpers/person.helper';
import { PersonId } from '../../../../models/aliases';
import { Role } from '../../../../models/core';
import { Services } from '../../../../models/services';
import { Snapshot } from '../../../../models/snapshot';
import { Type } from '../../../../models/type';
import RichEditor from '../../../shared/rich-editor';
import TypeLink from '../../../shared/sidebar-link/type-link';
import Sidebar from './sidebar';

export default class SidebarRole extends Sidebar<Role> {

    protected renderContent(role: Role, snapshot: Snapshot, services: Services): React.ReactElement | null {
        return (
            <>
                {this.renderDescription(role)}
                {this.renderMembers(role.members)}
            </>
        );
    }

    private renderDescription(role: Role): ReactElement {
        return <>
            <Divider horizontal
                     content="Omschrijving"/>
            <RichEditor initialMarkdown={role.description}
                        placeholder="Geen omschrijving"
                        readonly/>
        </>;
    }

    private renderMembers(members: PersonId[]) {
        return <>
            <Divider horizontal
                     content="Leden"/>
            {members.map(personId => {
                const person = this.props.snapshot.personMap.get(personId)!!;
                const func = this.props.snapshot.functionMap.get(person.function)!!;
                return <div key={personId}
                            className="tw-flex tw-justify-between">
                    <TypeLink name={personToFullName(person)}
                              type={Type.Person}
                              id={person.id}/>
                    <TypeLink name={func.name}
                              type={Type.Function}
                              id={func.id}/>
                </div>;
            })}
        </>;
    }
}
