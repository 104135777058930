import React, {ReactElement} from 'react';
import {Field, Form, Formik, FormikProps, FormikValues} from "formik";
import * as Yup from "yup";
import {DropdownItemProps, FormDropdown, FormInput} from "semantic-ui-react";
import {Person} from "../../../../models/core";
import {generateTempId} from "../../../../helpers/global.helper";
import ErrorLabel from "../../../shared/error-label";
import {updateSnapshotData} from "../../../../models/snapshot";
import BaseViewEdit from "../base-view-edit";
import FileUploader from "../../file-uploader/file-uploader";
import {KeycloakRoles} from "../../../../models/user";

export default class EditPerson extends BaseViewEdit<Person> {

    private formRef = React.createRef<FormikProps<any>>();

    protected initialiseState(): {} {
        return {};
    }

    protected getTitle(): string {
        const person = this.props.data;
        return person ? `${[person.name, person.prefix, person.surname].join(' ')}` : 'Nieuw persoon aanmaken';
    }

    protected renderContent(): ReactElement {
        const person = this.props.data;
        const user = this.props.user;
        const limitedEditing = !user.roles.has(KeycloakRoles.OrganisationManager)
            && !user.roles.has(KeycloakRoles.SuperAdmin);

        return <>
            <Formik
                initialValues={{
                    name: person?.name ?? '',
                    prefix: person?.prefix ?? '',
                    surname: person?.surname ?? '',
                    email: person?.email ?? '',
                    address: person?.address ?? '',
                    phone: person?.phone ?? '',
                    photo: person?.photo ?? '',
                    function: person?.function ?? '',
                }}
                validationSchema={Yup.object({
                    name: Yup.string().required("Voornaam is vereist").max(60, "Maximale lengte 60"),
                    prefix: Yup.string().nullable(),
                    surname: Yup.string().required("Achternaam is vereist").max(60, "Maximale lengte 60"),
                    email: Yup.string().required("Achternaam is vereist").email("Moet een e-mailadres zijn"),
                    address: Yup.string().nullable(),
                    phone: Yup.string().nullable(),
                    photo: Yup.string().nullable(),
                    function: Yup.string().required("Functie is vereist"),
                })}
                onSubmit={(values: FormikValues) => {
                    const person = {
                        ...(this.props.data ?? {
                            id: generateTempId()
                        }), ...values
                    } as Person;
                    this.save(person)
                }}
                innerRef={this.formRef}>
                {({errors, setFieldTouched, setFieldValue}) =>
                    <Form>
                        <Field
                            name="name"
                            as={FormInput}
                            fluid
                            label="Voornaam"
                            placeholder="Voornaam"
                            disabled={limitedEditing}
                        />
                        <ErrorLabel content={errors.name as string}/>
                        <Field
                            name="prefix"
                            as={FormInput}
                            fluid
                            label="Tussenvoegsel"
                            placeholder="Tussenvoegsel"
                            disabled={limitedEditing}
                        />
                        <ErrorLabel content={errors.prefix as string}/>
                        <Field
                            name="surname"
                            as={FormInput}
                            fluid
                            label="Achternaam"
                            placeholder="Achternaam"
                            disabled={limitedEditing}
                        />
                        <ErrorLabel content={errors.surname as string}/>
                        <Field
                            name="email"
                            as={FormInput}
                            fluid
                            label="E-mail"
                            placeholder="E-mail"
                            disabled={limitedEditing}
                        />
                        <ErrorLabel content={errors.email as string}/>
                        <Field
                            name="address"
                            as={FormInput}
                            fluid
                            label="Adres"
                            placeholder="Adres"
                        />
                        <ErrorLabel content={errors.address as string}/>
                        <Field
                            name="phone"
                            as={FormInput}
                            fluid
                            label="Telefoon"
                            placeholder="Telefoon"
                        />
                        <ErrorLabel content={errors.phone as string}/>
                        <label>Foto</label>
                        <Field
                            name="photo"
                            as={FileUploader}
                            placeholder="Afbeelding selecteren"
                            onChange={(value: File | null) => setFieldValue("photo", value)}
                            error={errors.photo !== undefined}
                        />
                        <ErrorLabel content={errors.photo as string}/>
                        <Field
                            name="function"
                            as={FormDropdown}
                            selection
                            search
                            options={this.functionOptions()}
                            fluid
                            label="Functie"
                            placeholder="Geen"
                            disabled={limitedEditing}
                            onBlur={(e: any, {name}: any) => setFieldTouched(name, true)}
                            onChange={(e: any, {name, value}: any) => setFieldValue(name, value)}
                        />
                        <ErrorLabel content={errors.function as string}/>
                    </Form>
                }
            </Formik>
        </>
    }

    private functionOptions(): DropdownItemProps[] {
        return this.props.snapshot.functions
            .sort((a, b) => a.name.localeCompare(b.name)).map<DropdownItemProps>(func => {
                return {
                    text: func.name,
                    value: func.id
                }
            });
    }

    protected onSubmit() {
        this.formRef.current?.handleSubmit();
    }

    protected async processAdd(person: Person): Promise<void> {
        return this.props.services.persons.addPerson(person).then((person: Person) =>
            updateSnapshotData(person, this.props.snapshot.persons, this.props.snapshot.personMap));
    }

    protected async processUpdate(person: Person): Promise<void> {
        return this.props.services.persons.updatePerson(person.id, person).then((person: Person) =>
            updateSnapshotData(person, this.props.snapshot.persons, this.props.snapshot.personMap));
    }

}
