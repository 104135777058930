import React from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { Type, Types } from '../../../models/type';
import { KeycloakRoles, User } from '../../../models/user';
import { onOpenView } from '../../app/graph-page/graph-page-container';

type Props = {
    user: User;
    onOpenAdminModal: onOpenView;
    disabled: boolean;
    className?: string;
};

const AdminDropdown = (props: Props) => {
    const views = availableAdminViews();

    if (views.length === 0) {
        return null;
    }

    return <Dropdown
        button
        className={'icon ' + props.className}
        disabled={props.disabled}
        floating
        labeled
        value={-1}
        icon="book"
        options={views}
        search
        text="Beheer.."
        selectOnBlur={false}
        selectOnNavigation={false}
        onChange={((e, prop) => {
            const type = prop.value as Type;
            const typeConfig = Types[type];
            props.onOpenAdminModal([{ view: typeConfig.admin!!.view }], typeConfig);
        })}/>;

    function availableAdminViews(): DropdownItemProps[] {
        return Object.entries(Types)
            .filter(([_key, type]) =>
                type.admin !== undefined
                && hasAccess(type.admin!!.roles))

            .map(([key, type]) => {
                    return {
                        text: type.name,
                        value: Number(key)
                    };
                }
            );
    }

    function hasAccess(allowedRoles: string[]): boolean {
        const userRoles = props.user.roles;
        return userRoles.has(KeycloakRoles.SuperAdmin) || allowedRoles.some(it => userRoles.has(it));
    }
};

export default AdminDropdown;
