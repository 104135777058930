import { CompositeDecorator, ContentBlock, ContentState, convertFromRaw, convertToRaw, DraftEditorCommand, Editor, EditorState, RichUtils } from 'draft-js';
import { draftToMarkdown, DraftToMarkdownOptions, markdownToDraft, MarkdownToDraftOptions } from 'markdown-draft-js';
import React, { Component } from 'react';
import { Button, Icon, Input, Popup, Segment, SemanticICONS } from 'semantic-ui-react';

type Props = {
    /**
     * The markdown value to be used
     */
    initialMarkdown?: string | null;

    /**
     * Placeholder for when no or an empty markdown is passed
     */
    placeholder?: string;

    /**
     * Additional class names for the top-level component
     */
    className?: string;

    /**
     * Turns the editor into a read-only Rich text viewer
     */
    readonly?: boolean;

    /**
     * Automatically focus on the editor on mount
     */
    autoFocus?: boolean;

    /**
     * Called when the user changes any text or layout
     * @param markdown
     */
    onChange?: (markdown: string) => void;

    /**
     * Called when the user loses focus on the editor
     * @param markdown
     */
    onBlur?: (markdown: string) => void;
};

type State = {
    editorState: EditorState;
    markdown: string;
    markdownMode: boolean;
    toolbarOpen: boolean;
    linkPopupOpen: boolean;
    linkUrl: string;
}

const EditorLink = (props: any) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url}
           target="_blank"
           className="tw-text-teal hover:tw-underline"
           rel="noopener noreferrer">{props.children}</a>
    );
};

const findLinkEntities = (
    block: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
) => {
    block.findEntityRanges(
        character => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
};

const editorDecorator = new CompositeDecorator([
    {
        strategy: findLinkEntities,
        component: EditorLink
    }
]);

const markdownToDraftOptions: MarkdownToDraftOptions = {
    remarkableOptions: {
        // Headings are disabled to keep the styling of description contents more predictable.
        // As headings will result in widely different font-sizes within a single description,
        // the tendency could arise to overcomplicate the contents of descriptions.
        disable: {
            block: ['heading']
        }
    },
    preserveNewlines: true
};

const draftToMarkdownOptions: DraftToMarkdownOptions = {
    preserveNewlines: true
};

export default class RichEditor extends Component<Props, State> {

    readonly state = this.initialiseState();

    private editorRef = React.createRef<any>();

    private initialiseState(): State {
        const markdown = this.props.initialMarkdown ?? '';
        const draftContent = markdownToDraft(markdown, markdownToDraftOptions);
        const contentState = convertFromRaw(draftContent);

        const editorState = EditorState.createWithContent(contentState, editorDecorator);

        return {
            editorState,
            markdown,
            markdownMode: false,
            toolbarOpen: false,
            linkPopupOpen: false,
            linkUrl: ''
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        const externalChangeOccurred = prevProps.initialMarkdown !== this.props.initialMarkdown;
        const internalStateDirty = this.props.initialMarkdown !== this.state.markdown;
        if (externalChangeOccurred && internalStateDirty) {
            this.setState(this.initialiseState());
        }
    }

    render() {
        return (
            <div className={this.props.className}>
                {
                    this.props.readonly ?
                        this.renderViewer() :
                        <Popup
                            open={this.state.toolbarOpen || this.state.linkPopupOpen}
                            flowing
                            content={this.renderToolbar()}
                            trigger={this.renderViewer()}/>
                }
            </div>
        );
    }

    private renderViewer() {
        const editor = <Editor
            ref={this.editorRef}
            editorState={this.state.editorState}
            readOnly={this.props.readonly}
            spellCheck={true}
            placeholder={this.props.placeholder}
            onChange={this.handleChange.bind(this)}
            handleKeyCommand={this.handleKeyCommand.bind(this)}
            onFocus={() => this.setState({ toolbarOpen: true })}
            onBlur={() => {
                this.setState({ toolbarOpen: false });
                this.props.onBlur?.(this.state.markdown);
            }}/>;

        if (this.props.readonly) {
            return editor;
        }

        return (
            <Segment className="tw-font-normal tw-shadow-none tw-min-w-64 tw-min-h-0 tw-py-2.5 tw-px-4 rich-editor"
                     onClick={(_: any) => this.editorRef.current.focus()}>
                {editor}
            </Segment>
        );
    }

    private renderToolbar() {
        const state = this.state.editorState;
        const currentStyle = state.getCurrentInlineStyle();

        const currentKey = state.getSelection().getStartKey();
        const currentBlockType = state.getCurrentContent().getBlockForKey(currentKey).getType();

        const markdownMode = this.state.markdownMode;

        return <>
            {this.renderIcon('bold', 'Bold', () => {
                this.handleChange(RichUtils.toggleInlineStyle(state, 'BOLD'));
            }, currentStyle.has('BOLD'), markdownMode)}
            {this.renderIcon('italic', 'Italic', () => {
                this.handleChange(RichUtils.toggleInlineStyle(state, 'ITALIC'));
            }, currentStyle.has('ITALIC'), markdownMode)}
            {this.renderIcon('underline', 'Underline', () => {
                this.handleChange(RichUtils.toggleInlineStyle(state, 'UNDERLINE'));
            }, currentStyle.has('UNDERLINE'), markdownMode)}
            {this.renderIcon('strikethrough', 'Strikethrough', () => {
                this.handleChange(RichUtils.toggleInlineStyle(state, 'STRIKETHROUGH'));
            }, currentStyle.has('STRIKETHROUGH'), markdownMode)}
            {this.renderIcon('code', 'Code', () => {
                this.handleChange(RichUtils.toggleBlockType(state, 'code-block'));
            }, currentBlockType === 'code-block', markdownMode)}
            {this.renderIcon('list ul', 'Unordered list', () => {
                this.handleChange(RichUtils.toggleBlockType(state, 'unordered-list-item'));
            }, currentBlockType === 'unordered-list-item', markdownMode)}
            {this.renderIcon('list ol', 'Ordered list', () => {
                this.handleChange(RichUtils.toggleBlockType(state, 'ordered-list-item'));
            }, currentBlockType === 'ordered-list-item', markdownMode)}
            {this.renderIcon('linkify', 'Link', () => this.setState(prevState => {
                const linkPopupOpen = !prevState.linkPopupOpen;
                const selectedEntity = this.getSelectedEntity(state);
                return {
                    linkPopupOpen,
                    linkUrl: linkPopupOpen ? selectedEntity?.getData().url ?? '' : ''
                };
            }), this.getSelectedEntity(state)?.getType() === 'LINK' || this.state.linkPopupOpen, markdownMode)}
            {this.renderIcon('arrow down', 'Markdown-modus', this.toggleMarkdownMode.bind(this),
                this.state.markdownMode
            )}
            {this.state.linkPopupOpen ? this.renderLinkInputs() : undefined}
        </>;
    }

    private renderIcon(
        icon: SemanticICONS,
        title: string,
        action: () => void,
        isActive: boolean,
        isDisabled: boolean = false
    ) {
        return (
            <span className={`tw-w-6 tw-h-6 tw-mr-1 tw-inline-flex tw-rounded hover:tw-bg-gray-light
            ${isActive ? 'tw-bg-gray-light ' : undefined}`}
                  onMouseDown={e => {
                      e.preventDefault();
                      if (!isDisabled) {
                          action();
                      }
                  }}
                  title={title}>
                  <Icon name={icon}
                        disabled={isDisabled}
                        className="tw-mx-auto tw-mt-0.5"/>
            </span>
        );
    }

    private renderLinkInputs() {
        return (
            <div className="tw-mt-4">
                <Input placeholder="URL"
                       defaultValue={this.state.linkUrl}
                       onChange={(e, prop) => {
                           this.setState({ linkUrl: prop.value });
                       }}/>
                <div className="tw-flex tw-mt-4">
                    <Button content="Toepassen"
                            className="tw-w-full tw-bg-teal tw-text-white hover:tw-bg-opacity-75"
                            onClick={this.handleLinkApply.bind(this)}/>
                    <Button icon
                            className="tw-bg-error tw-text-white hover:tw-bg-opacity-75"
                            onClick={this.handleLinkRemove.bind(this)}>
                        <Icon name="delete"/>
                    </Button>
                </div>
            </div>
        );
    }

    private handleChange(editorState: EditorState) {
        const currentContent = editorState.getCurrentContent();
        const markdown = this.state.markdownMode
            ? currentContent.getPlainText()
            : draftToMarkdown(convertToRaw(currentContent), draftToMarkdownOptions);
        this.setState({ editorState, markdown });
        this.props.onChange?.(markdown);
    }

    private handleKeyCommand(command: DraftEditorCommand, state: EditorState) {
        if (this.state.markdownMode) {
            return 'not-handled';
        }
        const newState = RichUtils.handleKeyCommand(state, command);
        if (newState) {
            this.handleChange(newState);
            return 'handled';
        }
        return 'not-handled';
    }

    private handleLinkApply(e: React.MouseEvent) {
        e.preventDefault();

        const state = this.state.editorState;

        let url = this.state.linkUrl;
        if (!/^https?:\/\//i.test(url)) {
            url = `http://${url}`;
        }

        const newContentState = state.getCurrentContent().createEntity('LINK', 'MUTABLE', {
            url
        });
        const entityKey = newContentState.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(state, { currentContent: newContentState });
        this.handleChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
        this.setState({ linkPopupOpen: false });
    }

    private handleLinkRemove(e: React.MouseEvent) {
        e.preventDefault();

        const state = this.state.editorState;

        // Remove link if the selected entity already is a link
        const selectedEntity = this.getSelectedEntity(state);
        if (selectedEntity?.getType() === 'LINK') {
            this.handleChange(RichUtils.toggleLink(state, state.getSelection(), null));
        }
        this.setState({ linkPopupOpen: false });
    }

    private getSelectedEntity(state: EditorState) {
        const selection = state.getSelection();
        const selectedBlock = state.getCurrentContent().getBlockForKey(selection.getStartKey());
        const selectedEntityKey = selectedBlock.getEntityAt(selection.getStartOffset());
        if (selectedEntityKey !== null) {
            return state.getCurrentContent().getEntity(selectedEntityKey);
        }
        return null;
    }

    private toggleMarkdownMode() {
        this.setState(state => {
            const markdownMode = !state.markdownMode;
            const content = markdownMode
                ? ContentState.createFromText(state.markdown)
                : convertFromRaw(markdownToDraft(state.markdown, markdownToDraftOptions));
            const editorState = EditorState.createWithContent(content, editorDecorator);
            return { markdownMode, editorState };
        });
    }
};
