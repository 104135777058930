import React, { Component, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { Services } from '../../../../models/services';
import { Snapshot } from '../../../../models/snapshot';
import { TypeConfig } from '../../../../models/type';
import { KeycloakRoles, User } from '../../../../models/user';
import Blip from '../../../shared/blip/blip';
import Finder from '../../../shared/finder/finder';
import BaseView from '../../../shared/modals/base-view';
import { Tab } from '../../../shared/modals/tabbed-modal';
import TypeIcon from '../../../shared/type-icon/type-icon';
import { onNavigateToData, onOpenView } from '../graph-page-container';
import logo from './logo-innovision.svg';

export const SIDEBAR_WIDTH = 340;

export type SidebarProps<T> = {
    data: T;
    typeConfig: TypeConfig;
    title: string;
    snapshot: Snapshot;
    services: Services;
    user: User;
    blipColor?: string;

    onNavigateToData: onNavigateToData;
    onOpenView: onOpenView;
}

export default abstract class Sidebar<T, S = {}> extends Component<SidebarProps<T>, S> {

    render() {
        const data = this.props.data;
        return (
            <div className="tw-shadow-2xl tw-flex tw-flex-col tw-h-100vh"
                 style={{
                     minWidth: SIDEBAR_WIDTH,
                     maxWidth: SIDEBAR_WIDTH
                 }}>
                {this.renderHeader()}
                <div className="tw-pt-0 tw-px-4 tw-overflow-y-auto tw-h-full">
                    {this.renderContent(data, this.props.snapshot, this.props.services)}
                </div>
            </div>
        );
    }

    protected renderHeader() {
        const typeConfig = this.props.typeConfig;
        const userRoles = this.props.user.roles;

        const tabs = typeConfig.editViews
            ?.filter(tab =>
                tab.roles.some(it => userRoles.has(it)
                    || userRoles.has(KeycloakRoles.SuperAdmin)
                    || this.hasAccessTo(tab.view)))
            .map<Tab>(it => {
                return {
                    name: it.name,
                    view: it.view
                };
            }) ?? [];

        const hasEditViews = (typeConfig.editViews?.length ?? 0) > 0;
        const hasPermission = tabs.length > 0 && this.props.snapshot.date === null;

        const editIconAction = hasPermission ?
            () => this.props.onOpenView(tabs, typeConfig, { data: this.props.data }) : undefined;

        return <div className="tw-pt-4 tw-px-4">
            <Link to="/"
                  className="tw-select-none tw-block tw-pb-4">
                <img src={logo}
                     className="tw-pointer-events-none tw-w-72"
                     alt="Avisi Innovision Logo"/>
            </Link>
            <Finder snapshot={this.props.snapshot}
                    onNavigateToData={this.props.onNavigateToData}/>
            <div className="tw-mt-4 tw-relative">
                <div className="tw-flex tw-items-start">
                    {typeConfig.icon && <TypeIcon typeConfig={typeConfig}
                                                  className="tw-mr-2"/>}
                    <div className="tw-pr-4">
                        <h2 className="tw-text-2xl tw-leading-6"
                            style={{
                                color: typeConfig.color
                            }}>
                            {this.getTitle()}
                            {this.props.blipColor && <Blip color={this.props.blipColor}
                                                           className="tw-ml-2"/>}
                        </h2>
                        <p className="tw-text-neutral tw-mt-1">{typeConfig.name}</p>
                    </div>
                </div>
                {hasEditViews &&
                <Icon className="tw-absolute tw-top-0 tw-right-0"
                      name="edit"
                      link={hasPermission}
                      disabled={!hasPermission}
                      onClick={editIconAction}/>}
            </div>

        </div>;
    }

    protected abstract renderContent(data: T, snapshot: Snapshot, services: Services): ReactElement | null;

    /**
     * Return true to give access to a view even without having the required role
     * @param view
     * @protected
     */
    protected hasAccessTo(view: typeof BaseView) {
        return false;
    }

    /**
     * Override to set a custom sidebar title
     * @protected
     */
    protected getTitle(): string {
        return this.props.title;
    }
};
