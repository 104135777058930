import React from 'react';
import { Divider, Loader, SidebarProps } from 'semantic-ui-react';
import { personToFullName } from '../../../../helpers/person.helper';
import { Experience, Function, Person } from '../../../../models/core';
import { Services } from '../../../../models/services';
import { Snapshot } from '../../../../models/snapshot';
import { Type } from '../../../../models/type';
import BaseView from '../../../shared/modals/base-view';
import TypeLink from '../../../shared/sidebar-link/type-link';
import Sidebar from './sidebar';

type State = {
    experiences: Experience[] | null;
}

const initialState: State = {
    experiences: null
};

export default class SidebarPerson extends Sidebar<Person> {

    readonly state = initialState;

    componentDidMount() {
        this.retrieveExperiences().then();
    }

    componentDidUpdate(prevProps: Readonly<SidebarProps>) {
        if (prevProps.data.id !== this.props.data.id) {
            this.setState({ teams: null });
            this.retrieveExperiences().then();
        }
    }

    protected renderContent(person: Person, snapshot: Snapshot, services: Services): React.ReactElement | null {
        const func = this.props.snapshot.functionMap.get(person.function)!!;
        return <>
            {this.renderOverview(person, func)}
            <Divider horizontal
                     content="Teams"/>
            {this.renderTeams()}
            {/* TODO: Badges Game <Divider horizontal*/}
            {/*         content="Ervaringen"/>*/}
            {/*{this.renderExperiencesContent()}*/}
        </>;
    }

    private renderOverview(person: Person, func: Function) {
        return <>
            <Divider horizontal
                     content="Algemeen"/>
            {person.anonymised && <p className="tw-mb-4 tw-italic">
                Deze persoon is geanonimiseerd.
                Alle persoonlijke informatie is uit Innovision verwijderd en kan niet meer achterhaald worden.
            </p>}
            <table className="tw-w-full">
                <tbody>
                    <tr>
                        <td>Naam</td>
                        <td>{person.anonymised ? '-' : personToFullName(person)}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{person.anonymised ? '-' : person.email}</td>
                    </tr>
                    <tr>
                        <td>Telefoon</td>
                        <td>{person.phone ?? '-'}</td>
                    </tr>
                    <tr>
                        <td>Adres</td>
                        <td>{person.address ?? '-'}</td>
                    </tr>
                    <tr>
                        <td>Functie</td>
                        <td><TypeLink name={func.name}
                                      type={Type.Function}
                                      id={func.id}/></td>
                    </tr>
                </tbody>
            </table>
        </>;
    }

    private renderTeams() {
        const person = this.props.data;
        const teams = this.props.snapshot.teams.filter(team => {
            return team.roles.some(role => role.members.some(member => member === person.id));
        });

        if (teams.length === 0) {
            return <p className="tw-text-neutral"><i>Deze persoon is geen lid van een team</i></p>;
        }

        return <div className="tw-grid tw-grid-cols-2 tw-gap-2">
            {teams.map(team => team.roles.map(role => {
                    const isMember = role.members.some(member => member === person.id);
                    return isMember ? <React.Fragment key={role.id}>
                        <TypeLink name={team.name}
                                  type={Type.Team}
                                  id={team.id}/>
                        {role.name}
                    </React.Fragment> : undefined;
                })
            )}
        </div>;
    }

    private renderExperiencesContent() {
        const experiences = this.state.experiences;

        if (!experiences) {
            return <Loader active
                           inline="centered"/>;
        }

        if (experiences.length === 0) {
            return <p className="tw-text-neutral"><i>Deze persoon heeft nog geen ervaringen</i></p>;
        }

        return experiences.map(experience => {
            const innovation = this.props.snapshot.innovationMap.get(experience.innovation)!!;
            return <h3 key={innovation.id}
                       className="tw-text-xl">
                <TypeLink name={innovation.name}
                          type={Type.Innovation}
                          id={innovation.id}/>
            </h3>;
        });
    }

    private async retrieveExperiences() {
        const personId = this.props.data.id;
        const experiences = await this.props.services.experiences.getExperiencesForPerson(
            personId,
            this.props.snapshot.date
        );
        this.setState({ experiences });
    }

    protected hasAccessTo(view: typeof BaseView): boolean {
        const person = this.props.data;
        return person.email === this.props.user.email;
    }

    protected getTitle(): string {
        return this.props.data.anonymised
            ? '<Anoniem>'
            : personToFullName(this.props.data);
    }
}
