import React, { ReactElement } from 'react';
import { Breadcrumb, Button, Icon, Table } from 'semantic-ui-react';
import { BreadcrumbSectionProps } from 'semantic-ui-react/dist/commonjs/collections/Breadcrumb/BreadcrumbSection';
import { findSuperCategories } from '../../../../helpers/innovation.helper';
import { CategoryId } from '../../../../models/aliases';
import { Category } from '../../../../models/core';
import { deleteSnapshotData } from '../../../../models/snapshot';
import { Type, Types } from '../../../../models/type';
import BaseViewAdmin from '../base-view-admin';
import EditCategory from '../edit-modals/edit-category';

export default class AdminCategories extends BaseViewAdmin {

    protected initialiseState(): {} {
        return {};
    }

    protected getTitle(): string {
        return 'Innovatie-categorieën beheren';
    }

    protected renderContent(): ReactElement {
        const sortedCategories = this.props.snapshot.categories
            .sort((a, b) => a.name.localeCompare(b.name));

        return <>
            <Button content="Categorie aanmaken"
                    className="tw-bg-teal tw-text-white hover:tw-bg-opacity-75"
                    onClick={_ => this.openEditor(null)}/>
            <Table basic="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Naam</Table.HeaderCell>
                        <Table.HeaderCell>Supercategorie</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Acties</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedCategories.map(this.renderRow.bind(this))}
                </Table.Body>
            </Table>
        </>;
    }

    private renderRow(category: Category): ReactElement {
        const categoryMap = this.props.snapshot.categoryMap;

        const sections = findSuperCategories(category.superCategory, categoryMap)
            .map<BreadcrumbSectionProps>(category => {
                return {
                    key: category.id,
                    content: category.name
                };
            });

        return (
            <Table.Row key={category.id}>
                <Table.Cell>{category.name}</Table.Cell>
                <Table.Cell> {
                    sections.length === 0 ? '-' :
                        <Breadcrumb icon="left angle"
                                    className="tw-text-center"
                                    sections={sections}/>
                }
                </Table.Cell>
                <Table.Cell textAlign="right">
                    <Icon name="edit"
                          link
                          onClick={() => this.openEditor(category)}/>
                    <Icon name="trash"
                          link
                          color="red"
                          onClick={() => this.delete(category.id, category.name)}/>
                </Table.Cell>
            </Table.Row>
        );
    }

    private openEditor(category: Category | null) {
        const tabs = category ? this.allowEditTabs() : [{ view: EditCategory }];
        this.props.onOpenSubView(tabs, Types[Type.Category], { data: category });
    }

    protected async processDelete(categoryId: CategoryId): Promise<void> {
        return this.props.services.categories.deleteCategory(categoryId).then(() =>
            deleteSnapshotData(categoryId, this.props.snapshot.categories, this.props.snapshot.categoryMap));
    }

}
