import { Innovation } from '../../../../models/core';
import { Services } from '../../../../models/services';
import { Snapshot } from '../../../../models/snapshot';
import { Type } from '../../../../models/type';
import { GraphData, GraphLink, GraphNode } from '../graph-page-view';
import { Force } from './shared';

/**
 * Creates a overview of all the listed innovations in a taxonomy structure.
 * If an innovation is provided, it will connect to teams that make use of this innovation.
 * @param innovation
 * @param snapshot
 * @param services
 */
export default async function innovationGraph(
    innovation: Innovation | null,
    snapshot: Snapshot,
    services: Services
): Promise<GraphData> {
    const nodes: GraphNode[] = [];
    const links: GraphLink[] = [];

    const defaultNode: GraphNode = { type: Type.Dummy, id: 'innovations', name: 'Innovaties' };
    nodes.push(defaultNode);

    snapshot.categories.forEach(category => {
        nodes.push({ type: Type.Category, id: category.id, name: category.name, data: category });
        links.push({
            type: Type.Category,
            source: category.id,
            target: category.superCategory ?? 'innovations',
            force: Force.Medium
        });
    });

    snapshot.innovations.forEach(innovation => {
        nodes.push({
            type: Type.Innovation,
            id: innovation.id,
            name: innovation.name,
            data: innovation
        });
        links.push({ type: Type.Innovation, source: innovation.id, target: innovation.category, force: Force.Large });
    });

    if (innovation) {
        const teamsUsingInnovation = await services.teams.getTeamsUsingInnovation(innovation.id, snapshot.date);
        teamsUsingInnovation.forEach(teamId => {
            const team = snapshot.teamMap.get(teamId)!!;
            nodes.push({ type: Type.Team, id: teamId, name: team.name, data: team });
            links.push({ type: Type.Team, source: innovation.id, target: teamId, force: Force.Medium });
        });
    }

    return { nodes, links, defaultNode };
}
