import React from 'react';
import { Button } from 'semantic-ui-react';
import BaseView from './base-view';

type Props<T> = {
    data: T | null;
}

export default abstract class BaseViewEdit<T, S = {}> extends BaseView<Props<T>, S> {

    protected getActions(): React.ReactElement | null {
        return <>
            <Button key="cancel"
                    content="Annuleren"
                    type="button"
                    className="tw-w-32 tw-mr-2"
                    onClick={() => this.props.onClose()}/>
            <Button key="publish"
                    content="Opslaan"
                    type="submit"
                    className="tw-w-32 tw-mr-0 tw-bg-teal tw-text-white hover:tw-bg-opacity-75"
                    onClick={() => this.onSubmit()}/>
        </>;
    }

    protected abstract onSubmit(): void;

    /**
     * Invoke this function to start the creation / updating process
     * Handles error checking and loading UI feedback
     * @param data the mutated or newly created data to store
     * @protected
     */
    protected save(data: T) {
        this.props.onSetLoading(true, 'Bezig met het verwerken van de gegevens...');
        const isNew = this.props.data === null;
        const promise = isNew ? this.processAdd(data) : this.processUpdate(data);
        promise.then(() => {
            this.props.onClose();
        }).catch(error => this.setState({ error }))
            .finally(() => this.props.onSetLoading(false, null));
    }

    protected abstract async processAdd(data: T): Promise<void>;

    protected abstract async processUpdate(data: T): Promise<void>;
}
