import { UsageStatus } from '../models/core';
import { lerp } from './global.helper';


export enum Branding {
    Text = '#333333',
    TextOpposite = '#FFFFFF',
    Neutral = '#7C7C7C',
    Teal = '#1199BB',
    TurquoiseLight = '#32BCDD',
    Information = '#3D81C3',
    Purple = '#774499',
    PurpleLight = '#9887BA',
    Error = '#D0131F',
    Green = '#33AA77',
    GreenLight = '#66BA98',
    Approval = '#83BC2C',
    Orange = '#FF9911',
    OrangeLight = '#EDAA56',
    Warning = '#F6A623',
}


export const StatusColor = {
    experiment: '#FF9911',
    adopt: '#460F8C',
    hold: '#00C113',
    scrap: '#FF0000'
};

export function statusColor(status: UsageStatus): string {
    switch (status) {
        case UsageStatus.EXPERIMENT:
            return StatusColor.experiment;
        case UsageStatus.ADOPT:
            return StatusColor.adopt;
        case UsageStatus.HOLD:
            return StatusColor.hold;
        case UsageStatus.SCRAP:
            return StatusColor.scrap;
    }
}

export const baseColours = [
    Branding.Teal,
    Branding.Purple,
    Branding.Green,
    Branding.Orange,
    Branding.Error,
    Branding.Information,
    Branding.TurquoiseLight,
    Branding.Approval,
    Branding.GreenLight,
    Branding.PurpleLight,
    Branding.OrangeLight
];

export type RGBColor = {
    r: number,
    g: number,
    b: number
}

export function lerpColors(
    colorA: RGBColor,
    colorB: RGBColor,
    factor: number
): RGBColor {
    return {
        r: lerp(colorA.r, colorB.r, factor),
        g: lerp(colorA.g, colorB.g, factor),
        b: lerp(colorA.b, colorB.b, factor)
    };
}

export function hexToRgb(hex: string): RGBColor | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export function rgbToHex(color: RGBColor): string {
    return '#' + componentToHex(color.r) + componentToHex(color.g) + componentToHex(color.b);
}

function componentToHex(c: number): string {
    c = Math.round(Math.min(Math.max(c, 0), 255));
    const hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
}
