import React from 'react';

type Props = {
    content?: string;
};

/**
 * Shows an error label under an input field
 */
const ErrorLabel = (props: Props) => {
    return <p className="tw-text-error tw-mt-1">{props.content}</p>;
};

export default ErrorLabel;
