import assert from 'assert';
import React, { ReactElement } from 'react';
import { Breadcrumb, BreadcrumbSectionProps, Card, Divider, DropdownItemProps, Icon, List } from 'semantic-ui-react';
import { StatusColor } from '../../../../helpers/colors.helper';
import { generateTempId } from '../../../../helpers/global.helper';
import { findSuperCategories } from '../../../../helpers/innovation.helper';
import { InnovationId } from '../../../../models/aliases';
import { Innovation, Team, Usage, UsageStatus } from '../../../../models/core';
import DropdownDialog from '../../dropdown-dialog/dropdown-dialog';
import ModalRichEditor from '../../modal-rich-editor/modal-rich-editor';
import RichEditor from '../../rich-editor';
import BaseViewEdit from '../base-view-edit';


enum UiAction {
    NOTHING,
    SELECTING_INNOVATION,
    UPDATING_RATIONALE
}


type State = {
    usages: Usage[];
    action: UiAction;
    selectingInnovationForStatus: UsageStatus | null;
    updatingRationaleForUsage: Usage | null;
};

export default class EditTeamInnovations extends BaseViewEdit<Team, State> {

    protected initialiseState(): State {
        return {
            usages: [],
            action: UiAction.NOTHING,
            selectingInnovationForStatus: null,
            updatingRationaleForUsage: null
        };
    }

    protected async onMount() {
        const team = this.props.data!!;
        const usageService = this.props.services.usages;

        this.props.onSetLoading(true, 'Bezig met het ophalen van gegevens...');
        const usages = await usageService.getUsagesByTeamId(team.id);
        this.setState({ usages });
        this.props.onSetLoading(false, null);
    }

    private dragUsage: Usage | null = null;
    private dragUsageTarget: UsageStatus | null = null;

    protected getTitle(): string {
        const team = this.props.data!!;
        return `${team.name} - Innovaties`;
    }

    protected renderContent(): ReactElement {
        const usagesExperiment = this.state.usages.filter(it => it.status === UsageStatus.EXPERIMENT);
        const usagesAdopt = this.state.usages.filter(it => it.status === UsageStatus.ADOPT);
        const usagesHold = this.state.usages.filter(it => it.status === UsageStatus.HOLD);
        const usagesScrap = this.state.usages.filter(it => it.status === UsageStatus.SCRAP);

        return <>
            <DropdownDialog title="Selecteer innovatie"
                            selectButtonTitle="Toevoegen"
                            placeholder="Selecteer innovatie"
                            open={this.state.action === UiAction.SELECTING_INNOVATION}
                            onCancel={() => this.handleAddUsage(null)}
                            onConfirm={(innovationId: InnovationId) => this.handleAddUsage(innovationId)}
                            options={this.selectInnovationOptions()}/>
            {this.state.action === UiAction.UPDATING_RATIONALE &&
            <ModalRichEditor title="Werk rationale bij"
                             selectButtonTitle="Bijwerken"
                             initialMarkdown={this.state.updatingRationaleForUsage?.rationale ?? undefined}
                             placeholder="Rationale"
                             open
                             onClose={this.handleUpdateRationale.bind(this)}>
                <Divider horizontal
                         content="Huidige rationale"/>
                <RichEditor initialMarkdown={this.state.updatingRationaleForUsage?.rationale ?? '*Geen*'}
                            readonly/>
                <Divider horizontal
                         content="Nieuwe rationale"/>
            </ModalRichEditor>
            }
            <div className="tw-flex tw-w-full tw-gap-4">
                {this.renderColumn('Experimenteren', usagesExperiment, UsageStatus.EXPERIMENT, StatusColor.experiment)}
                {this.renderColumn('Adopteren', usagesAdopt, UsageStatus.ADOPT, StatusColor.adopt)}
                {this.renderColumn('Continueren', usagesHold, UsageStatus.HOLD, StatusColor.hold)}
                {this.renderColumn('Uitfaseren', usagesScrap, UsageStatus.SCRAP, StatusColor.scrap)}
            </div>
        </>;
    }

    private selectInnovationOptions(): DropdownItemProps[] {
        return this.props.snapshot.innovations
            .filter(it => !this.state.usages.find(usage => it.id === usage.innovation))
            .map(innovation => {
                const categoryMap = this.props.snapshot.categoryMap;
                const superCategories = findSuperCategories(innovation.category, categoryMap)
                    .map(it => it.name).join(' < ');

                return {
                    value: innovation.id,
                    text: innovation.name,
                    description: superCategories
                };
            });
    }

    private renderColumn(title: string, usages: Usage[], status: UsageStatus, color: string) {
        return <div className="tw-w-1/4 tw-min-h-60vh tw-shadow-inner tw-bg-white-gray tw-p-2"
                    onDragOver={(e: any) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    onDrop={() => this.handleDrop(status)}>
            <h3 className="tw-text-2xl tw-inline tw-font-bold"
                style={{ color }}>{title}</h3>
            <Icon name="add"
                  link
                  color="green"
                  size="large"
                  onClick={() => this.startSelectingInnovation(status)}/>
            {this.renderUsages(usages, color)}
        </div>;
    }

    private renderUsages(usages: Usage[], color: string): ReactElement[] {
        return usages.map(usage => {
            const innovation = this.props.snapshot.innovationMap.get(usage.innovation)!!;
            return <Card key={usage.id}
                         draggable
                         fluid
                         title={usage.rationale}
                         onDragStart={(_: any) => {
                             this.dragUsage = usage;
                         }}>
                <List.Content className="tw-cursor-pointer hide-child tw-p-2">
                    {this.renderBreadcrumb(innovation, color)}
                    <Icon name="trash"
                          className="child tw-text-error tw-absolute tw-top-1/2 tw-text-center tw-right-0 translate-center"
                          onClick={() => this.handleRemoveUsage(usage)}/>
                </List.Content>
            </Card>;
        });
    }

    private renderBreadcrumb(innovation: Innovation, color: string): ReactElement {
        const categoryMap = this.props.snapshot.categoryMap;
        const sections = findSuperCategories(innovation.category, categoryMap)
            .map<BreadcrumbSectionProps>(category => {
                return {
                    key: category.id,
                    content: category.name
                };
            });

        sections.unshift({
            key: innovation.id,
            content: innovation.name,
            active: true,
            style: { color }
        });

        return <Breadcrumb icon="left angle"
                           className="tw-text-center tw-leading-relaxed"
                           sections={sections}/>;
    }

    private handleAddUsage(innovationId: InnovationId | null) {
        this.stopSelectingInnovation();

        if (innovationId && this.state.selectingInnovationForStatus !== null) {
            const usage = {
                id: generateTempId(),
                innovation: innovationId,
                rationale: null,
                status: this.state.selectingInnovationForStatus
            };
            this.setState(state => {
                const usages = state.usages;
                usages.push(usage);
                return { usages };
            });
            this.startUpdatingRationale(usage);
        }
    }

    private handleUpdateRationale(saved: boolean, rationale: string | null) {
        const usage = this.state.updatingRationaleForUsage;
        if (saved && usage !== null) {
            usage.status = this.dragUsageTarget ?? usage.status;
            usage.rationale = rationale === null || rationale.length === 0 ? null : rationale;
            this.forceUpdate();
        }
        this.dragUsage = null;
        this.dragUsageTarget = null;
        this.stopUpdatingRationale();
    }

    private handleRemoveUsage(usage: Usage) {
        const team = this.props.data!!;
        const innovation = this.props.snapshot.innovationMap.get(usage.innovation)!!;
        if (window.confirm(`Weet je zeker dat je de innovatie ${innovation.name} wilt verwijderen uit ${team.name}?`)) {
            this.setState({
                usages: this.state.usages.filter(it => it.id !== usage.id)
            });
        }
    }

    private handleDrop(newStatus: UsageStatus) {
        const usage = this.dragUsage;
        if (!usage || usage.status === newStatus) {
            return;
        }

        this.dragUsageTarget = newStatus;
        this.startUpdatingRationale(usage);
    }

    private startSelectingInnovation(forStatus: UsageStatus) {
        this.setState({
            action: UiAction.SELECTING_INNOVATION,
            selectingInnovationForStatus: forStatus
        });
    }

    private stopSelectingInnovation() {
        this.setState({
            action: UiAction.NOTHING,
            selectingInnovationForStatus: null
        });
    }

    private startUpdatingRationale(forUsage: Usage) {
        this.setState({
            action: UiAction.UPDATING_RATIONALE,
            updatingRationaleForUsage: forUsage
        });
    }

    private stopUpdatingRationale() {
        this.setState({
            action: UiAction.NOTHING,
            updatingRationaleForUsage: null
        });
    }

    protected onSubmit() {
        const team = this.props.data!!;
        this.save(team);
    }

    protected async processAdd(team: Team): Promise<void> {
        assert(false, 'Innovations should never be editable during team creation');
    }

    protected async processUpdate(team: Team): Promise<void> {
        return this.props.services.usages.postTeamUsages(team.id, this.state.usages).then(usages => {
            team.usages = usages.map(it => it.id);
        });
    }
}
