import React, {ReactElement} from 'react';
import {Field, Form, Formik, FormikProps, FormikValues} from "formik";
import * as Yup from "yup";
import {FormInput} from "semantic-ui-react";
import {Function} from "../../../../models/core";
import {generateTempId} from "../../../../helpers/global.helper";
import ErrorLabel from "../../../shared/error-label";
import RichEditor from "../../../shared/rich-editor";
import {updateSnapshotData} from "../../../../models/snapshot";
import BaseViewEdit from "../base-view-edit";

export default class EditFunction extends BaseViewEdit<Function> {

    private formRef = React.createRef<FormikProps<any>>();

    protected initialiseState(): {} {
        return {};
    }

    protected getTitle(): string {
        const func = this.props.data;
        return func ? `${func.name}` : 'Nieuwe functie aanmaken';
    }

    protected renderContent(): ReactElement {
        const func = this.props.data;
        return <>
            <Formik
                initialValues={{
                    name: func?.name ?? '',
                    description: func?.description ?? null
                }}
                validationSchema={Yup.object({
                    name: Yup.string().required("Functienaam is vereist").max(60, "Maximale lengte 60"),
                    description: Yup.string().nullable().min(10, "Minimale lengte 10").max(1000, "Maximale lengte 1000")
                })}
                onSubmit={(values: FormikValues) => {
                    const func = {
                        ...(this.props.data ?? {
                            id: generateTempId()
                        }), ...values
                    } as Function;
                    this.save(func)
                }}
                innerRef={this.formRef}>
                {({errors, setFieldTouched, setFieldValue, values}) =>
                    <Form>
                        <Field
                            name="name"
                            as={FormInput}
                            fluid
                            label="Functienaam"
                            placeholder="Functienaam"
                        />
                        <ErrorLabel content={errors.name as string}/>
                        <label>Omschrijving</label>
                        <Field
                            name="description"
                            as={RichEditor}
                            fluid
                            initialMarkdown={values.description}
                            placeholder="Omschrijving"
                            onChange={(markdown: string) => setFieldValue("description", markdown)}
                            onBlur={() => setFieldTouched("description", true)}
                        />
                        <ErrorLabel content={errors.description as string}/>
                    </Form>
                }
            </Formik>
        </>
    }

    protected onSubmit() {
        this.formRef.current?.handleSubmit();
    }

    protected async processAdd(func: Function): Promise<void> {
        return this.props.services.functions.addFunction(func).then((func: Function) =>
            updateSnapshotData(func, this.props.snapshot.functions, this.props.snapshot.functionMap));
    }

    protected async processUpdate(func: Function): Promise<void> {
        return this.props.services.functions.updateFunction(func.id, func).then((func: Function) =>
            updateSnapshotData(func, this.props.snapshot.functions, this.props.snapshot.functionMap));
    }

}
