import React from 'react';
import { Divider, Loader, SidebarProps } from 'semantic-ui-react';
import { Innovation, Team } from '../../../../models/core';
import { Services } from '../../../../models/services';
import { Snapshot } from '../../../../models/snapshot';
import { Type } from '../../../../models/type';
import RichEditor from '../../../shared/rich-editor';
import TypeLink from '../../../shared/sidebar-link/type-link';
import Sidebar from './sidebar';

type State = {
    teams: Team[] | null;
}

const initialState: State = {
    teams: null
};

export default class SidebarInnovation extends Sidebar<Innovation, State> {

    readonly state = initialState;

    componentDidMount() {
        this.retrieveTeamsUsingInnovation().then();
    }

    componentDidUpdate(prevProps: Readonly<SidebarProps>) {
        const prevInnovation = prevProps.data;
        const innovation = this.props.data;
        if (prevInnovation.id !== innovation.id) {
            this.setState({ teams: null });
            this.retrieveTeamsUsingInnovation().then();
        }
    }

    private async retrieveTeamsUsingInnovation() {
        const innovation = this.props.data;
        const teamsIds = await this.props.services.teams.getTeamsUsingInnovation(
            innovation.id,
            this.props.snapshot.date
        );
        const teams = teamsIds.map(teamId => this.props.snapshot.teamMap.get(teamId)!!);

        this.setState({ teams });
    }

    private renderInnovation(innovation: Innovation) {
        return <>
            <Divider horizontal
                     content="Omschrijving"/>
            <RichEditor initialMarkdown={innovation.description}
                        placeholder="Geen omschrijving"
                        readonly/>
        </>;
    }

    private renderTeams() {
        return <>
            <Divider horizontal
                     content="Teams"/>
            {this.renderTeamsContent()}
        </>;
    }

    private renderTeamsContent() {
        const teams = this.state.teams;

        if (!teams) {
            return <Loader active
                           inline="centered"/>;
        }

        if (teams.length === 0) {
            return <p className="tw-text-neutral"><i>Geen teams maken gebruik van deze innovatie</i></p>;
        }

        return teams.map(team => {
            return (
                <h3 className="tw-text-xl"
                    key={team.id}>
                    <TypeLink name={team.name}
                              type={Type.Team}
                              id={team.id}/>
                </h3>
            );
        });
    }

    protected renderContent(innovation: Innovation, snapshot: Snapshot, services: Services): React.ReactElement | null {
        return <>
            {this.renderInnovation(innovation)}
            {this.renderTeams()}
        </>;
    }
}
