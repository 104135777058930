import React, { PureComponent } from 'react';
import { Branding } from '../../../helpers/colors.helper';

type Props = {
    content: string;
    colour: string;
};

/**
 * This displays a message in the middle of the page.
 */
export default class PageMessage extends PureComponent<Props> {

    static defaultProps = {
        colour: Branding.Text
    };

    render() {
        return (
            <h1 className="tw-text-4xl tw-absolute tw-top-1/2 tw-left-1/2 translate-center"
                style={{ color: this.props.colour }}>
                {this.props.content}
            </h1>
        );
    }
};

