import {TeamId} from './aliases';


export type User = {
    name: string;
    surname: string;
    email: string;
    token: string;
    roles: Set<string>;
    teams: Set<TeamId> | null;
}

export enum KeycloakRoles {
    Everyone = "user",
    SuperAdmin = "super_admin",
    OrganisationManager = "organisation_manager",
    InnovationManager = "innovation_manager"
}
