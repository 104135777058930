import { GroupId } from '../models/aliases';
import { Group } from '../models/core';
import ApiService from './api-service';

export default class GroupService extends ApiService {

    async getGroups(date: Date | null): Promise<Group[]> {
        const path = `/groups/`;
        return await this.get(path, date ?? undefined);
    }

    async addGroup(group: Group): Promise<Group> {
        const path = `/groups`;
        const nullGroup = this.ensureNoTempIds(group)[0];
        return await this.post(path, nullGroup);
    }

    async updateGroup(groupId: GroupId, group: Group): Promise<Group> {
        const path = `/groups/${groupId}`;
        const nullGroup = this.ensureNoTempIds(group)[0];
        return await this.patch(path, nullGroup);
    }

    async deleteGroup(groupId: GroupId): Promise<void> {
        const path = `/groups/${groupId}`;
        return await this.delete(path);
    }

}
