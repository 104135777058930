import BaseView from "./base-view";
import {KeycloakRoles} from "../../../models/user";
import {Tab} from "./tabbed-modal";

export default abstract class BaseViewAdmin extends BaseView {

    protected delete(dataId: string, dataName: string) {
        const isConfirmed = window.confirm(`Weet je zeker dat je ${dataName} wilt verwijderen?\nDeze actie kan niet ongedaan worden.`);
        if (isConfirmed) {
            this.props.onSetLoading(true, "Bezig met het verwijderen van de node...");
            this.processDelete(dataId)
                .then(() => this.setState({error: null}))
                .catch(error => this.setState({error}))
                .finally(() => this.props.onSetLoading(false, null));
        }
    }

    protected abstract async processDelete(dataId: string): Promise<void>

    protected allowEditTabs(): Tab[] {
        const roles = this.props.user.roles;
        return this.props.typeConfig.editViews
            ?.filter(tab => tab.roles.some(it => roles.has(it) || roles.has(KeycloakRoles.SuperAdmin)))
            .map<Tab>(it => {
                return {
                    name: it.name,
                    view: it.view
                };
            }) ?? [];
    }
}
