import { Field, Form, Formik, FormikProps, FormikValues } from 'formik';
import React, { ReactElement } from 'react';
import { DropdownItemProps, FormDropdown, FormInput } from 'semantic-ui-react';
import * as Yup from 'yup';
import { generateTempId } from '../../../../helpers/global.helper';
import { Team } from '../../../../models/core';
import { updateSnapshotData } from '../../../../models/snapshot';
import { KeycloakRoles } from '../../../../models/user';
import ErrorLabel from '../../../shared/error-label';
import FileUploader from '../../file-uploader/file-uploader';
import RichEditor from '../../rich-editor';
import BaseViewEdit from '../base-view-edit';

export default class EditTeam extends BaseViewEdit<Team> {

    private formRef = React.createRef<FormikProps<any>>();

    protected initialiseState(): {} {
        return {};
    }

    protected getTitle(): string {
        const team = this.props.data;
        return team ? `${team.name}` : 'Nieuw team aanmaken';
    }

    protected renderContent(): ReactElement {
        const team = this.props.data;
        const user = this.props.user;
        const limitedEditing = !user.roles.has(KeycloakRoles.OrganisationManager)
            && !user.roles.has(KeycloakRoles.SuperAdmin);

        return <>
            <Formik
                initialValues={{
                    name: team?.name ?? '',
                    description: team?.description ?? null,
                    logo: team?.logo ?? '',
                    group: team?.group
                }}
                validationSchema={Yup.object({
                    name: Yup.string().required('Teamnaam is vereist'),
                    description: Yup.string().nullable().min(10, 'Minimale lengte 10').max(
                        1000,
                        'Maximale lengte 1000'
                    ),
                    logo: Yup.object().nullable(),
                    group: Yup.string().required('Groep is vereist')
                })}
                onSubmit={(values: FormikValues) => {
                    const team = {
                        ...(this.props.data ?? {
                            id: generateTempId(),
                            roles: [],
                            usages: []
                        }), ...values
                    } as Team;
                    this.save(team);
                }}
                innerRef={this.formRef}>
                {({ errors, values, setFieldTouched, setFieldValue }) =>
                    <Form>
                        <label>Teamnaam</label>
                        <Field
                            name="name"
                            as={FormInput}
                            fluid
                            placeholder="Teamnaam"
                            disabled={limitedEditing}
                        />
                        <ErrorLabel content={errors.name as string}/>
                        <label>Omschrijving</label>
                        <Field
                            name="description"
                            as={RichEditor}
                            fluid
                            initialMarkdown={values.description}
                            placeholder="Omschrijving"
                            onChange={(markdown: string) => setFieldValue('description', markdown)}
                            onBlur={() => setFieldTouched('description', true)}
                        />
                        <ErrorLabel content={errors.description as string}/>
                        <label>Logo</label>
                        <Field
                            name="logo"
                            as={FileUploader}
                            placeholder="Afbeelding selecteren"
                            onChange={(value: File | null) => setFieldValue('logo', value)}
                            disabled={limitedEditing}
                        />
                        <ErrorLabel content={errors.logo as string}/>
                        <label>Groep</label>
                        <Field
                            name="group"
                            as={FormDropdown}
                            selection
                            search
                            options={this.groupOptions()}
                            fluid
                            placeholder="Groep"
                            onBlur={(e: any, { name, value }: any) => setFieldTouched(name, value)}
                            onChange={(e: any, { name, value }: any) => setFieldValue(name, value)}
                            disabled={limitedEditing}
                        />
                        <ErrorLabel content={errors.group as string}/>
                    </Form>
                }
            </Formik>
        </>;
    }

    private groupOptions(): DropdownItemProps[] {
        return this.props.snapshot.groups.map<DropdownItemProps>(group => {
            return {
                text: group.name,
                value: group.id
            };
        });
    }

    protected onSubmit() {
        this.formRef.current?.handleSubmit();
    }

    protected async processAdd(team: Team): Promise<void> {
        team.logo = await this.handleLogo(team.logo as File | null);
        return this.props.services.teams.addTeam(team).then((team: Team) =>
            updateSnapshotData(team, this.props.snapshot.teams, this.props.snapshot.teamMap));
    }

    protected async processUpdate(team: Team): Promise<void> {
        team.logo = await this.handleLogo(team.logo as File | null);
        return this.props.services.teams.updateTeam(team.id, team).then((team: Team) =>
            updateSnapshotData(team, this.props.snapshot.teams, this.props.snapshot.teamMap));
    }

    private async handleLogo(file: File | null): Promise<string | null> {
        // TODO implement TRA-215
        return null;
    }

}
