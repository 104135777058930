import {CategoryId, CategoryMap} from "../models/aliases";
import {Category} from "../models/core";

/**
 * Returns an array of categories, starting with the base category up to the highest super category
 * Gives back an empty array when a null base category is provided
 * @param baseCategory
 * @param categoryMap
 */
export function findSuperCategories(baseCategory: CategoryId | null, categoryMap: CategoryMap): Category[] {
    if (baseCategory === null) {
        return [];
    }

    const categories = new Array<Category>();

    let superCategoryId: CategoryId | null = baseCategory;
    do {
        const category: Category = categoryMap.get(superCategoryId)!!;
        categories.push(category);
        superCategoryId = category.superCategory;
    } while (superCategoryId);

    return categories;
}
