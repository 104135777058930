import React, { ReactElement } from 'react';
import { Button, Icon, Table } from 'semantic-ui-react';
import { GroupId } from '../../../../models/aliases';
import { Group } from '../../../../models/core';
import { deleteSnapshotData } from '../../../../models/snapshot';
import { Type, Types } from '../../../../models/type';
import BaseViewAdmin from '../base-view-admin';
import EditGroup from '../edit-modals/edit-group';

export default class AdminGroups extends BaseViewAdmin {

    protected initialiseState(): {} {
        return {};
    }

    protected getTitle(): string {
        return 'Groepen beheren';
    }

    protected renderContent(): ReactElement {
        const sortedGroups = this.props.snapshot.groups
            .sort((a, b) => a.name.localeCompare(b.name));

        return <>
            <Button content="Groep aanmaken"
                    className="tw-bg-teal tw-text-white hover:tw-bg-opacity-75"
                    onClick={_ => this.openEditor(null)}/>
            <Table basic="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Naam</Table.HeaderCell>
                        <Table.HeaderCell>Supergroep</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Acties</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {sortedGroups.map(this.renderRow.bind(this))}
                </Table.Body>
            </Table>
        </>;
    }

    private renderRow(group: Group): ReactElement {
        const groupMap = this.props.snapshot.groupMap;
        const superGroup = group.superGroup ? groupMap.get(group.superGroup)!! : null;
        return (
            <Table.Row key={group.id}>
                <Table.Cell>{group.name}</Table.Cell>
                <Table.Cell>{superGroup?.name ?? '-'}</Table.Cell>
                <Table.Cell textAlign="right">
                    <Icon name="edit"
                          link
                          onClick={() => this.openEditor(group)}/>
                    <Icon name="trash"
                          link
                          color="red"
                          onClick={() => this.delete(group.id, group.name)}/>
                </Table.Cell>
            </Table.Row>
        );
    }

    private openEditor(group: Group | null) {
        const tabs = group ? this.allowEditTabs() : [{ view: EditGroup }];
        this.props.onOpenSubView(tabs, Types[Type.Group], { data: group });
    }

    protected async processDelete(groupId: GroupId): Promise<void> {
        return this.props.services.groups.deleteGroup(groupId).then(() =>
            deleteSnapshotData(groupId, this.props.snapshot.groups, this.props.snapshot.groupMap));
    }

}
