import { InnovationId } from '../models/aliases';
import { Innovation } from '../models/core';
import ApiService from './api-service';

export default class InnovationService extends ApiService {

    async getInnovations(date: Date | null): Promise<Innovation[]> {
        const path = `/innovations/`;
        return await this.get(path, date ?? undefined);
    }

    async addInnovation(innovation: Innovation): Promise<Innovation> {
        const path = `/innovations`;
        const nullInnovation = this.ensureNoTempIds(innovation)[0];
        return await this.post(path, nullInnovation);
    }

    async updateInnovation(innovationId: InnovationId, innovation: Innovation): Promise<Innovation> {
        const path = `/innovations/${innovationId}`;
        const nullInnovation = this.ensureNoTempIds(innovation)[0];
        return await this.patch(path, nullInnovation);
    }

    async deleteInnovation(innovationId: InnovationId): Promise<void> {
        const path = `/innovations/${innovationId}`;
        return await this.delete(path);
    }

}
