import React from 'react';
import { Divider } from 'semantic-ui-react';
import { Innovation, Usage } from '../../../../models/core';
import { Services } from '../../../../models/services';
import { Snapshot } from '../../../../models/snapshot';
import { Type } from '../../../../models/type';
import RichEditor from '../../../shared/rich-editor';
import TypeLink from '../../../shared/sidebar-link/type-link';
import UsageStatusDisplay from '../../../shared/usage-status-display/usage-status-display';
import Sidebar from './sidebar';

export default class SidebarUsage extends Sidebar<Usage> {

    protected renderContent(usage: Usage, snapshot: Snapshot, services: Services): React.ReactElement | null {
        const innovation = this.props.snapshot.innovationMap.get(usage.innovation)!!;

        return <>
            {this.renderStatus(usage)}
            {this.renderInnovation(innovation)}
        </>;
    }

    private renderStatus(usage: Usage) {
        return <>
            <Divider horizontal
                     content="Status"/>
            <UsageStatusDisplay status={usage.status}/>
            <p className="tw-mt-4"><strong>Rationale</strong></p>
            <RichEditor initialMarkdown={usage.rationale}
                        placeholder="Geen rationale"
                        readonly/>
        </>;
    }

    private renderInnovation(innovation: Innovation) {
        return <>
            <Divider horizontal
                     content="Innovatie"/>
            <TypeLink name={innovation.name}
                      id={innovation.id}
                      type={Type.Innovation}
                      className="tw-text-xl"/>
            <RichEditor initialMarkdown={innovation.description}
                        placeholder="Geen omschrijving"
                        readonly/>
        </>;
    }
}
