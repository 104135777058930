import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import RichEditor from '../rich-editor';

export type onCloseModalRichEditor = (saved: boolean, value: string | null) => void;

type Props = {
    title: string;
    selectButtonTitle: string;
    initialMarkdown?: string;

    children?: React.ReactNode
    placeholder?: string;
    open?: boolean;

    onClose: onCloseModalRichEditor;
};

type State = {
    markdown: string;
}

export default class ModalRichEditor extends Component<Props, State> {

    readonly state = {
        markdown: this.props.initialMarkdown ?? ''
    };

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.initialMarkdown !== this.props.initialMarkdown
            && this.state.markdown !== this.props.initialMarkdown) {
            this.setState({ markdown: this.props.initialMarkdown ?? '' });
        }
    }

    render() {
        return <Modal closeIcon
                      onClose={_ => this.props.onClose(false, null)}
                      open={this.props.open}>
            <Modal.Header>{this.props.title}</Modal.Header>
            <Modal.Content>
                {this.props.children}
                <RichEditor
                    placeholder={this.props.placeholder}
                    initialMarkdown={this.state.markdown}
                    onChange={(markdown) => this.setState({ markdown })}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button key="cancel"
                        content="Annuleren"
                        onClick={_ => this.props.onClose(false, null)}/>
                <Button key="select"
                        content={this.props.selectButtonTitle}
                        className="tw-bg-teal tw-text-white hover:tw-bg-opacity-75"
                        onClick={_ => {
                            this.props.onClose(true, this.state.markdown);
                        }}/>
            </Modal.Actions>
        </Modal>;
    }
};
