import React from 'react';
import { Divider } from 'semantic-ui-react';
import { Services } from '../../../../models/services';
import { Snapshot } from '../../../../models/snapshot';
import Sidebar from './sidebar';

export default class SidebarDummy extends Sidebar<void> {

    protected renderContent(dummy: void, snapshot: Snapshot, services: Services): React.ReactElement | null {
        return <>
            <Divider/>
            <p><i>Deze node representeert geen data en dient enkel als hulpmiddel voor de visualisatie.</i></p>
        </>;
    }
}
