import React from 'react';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { TypeConfig } from '../../../models/type';

type Props = {
    typeConfig: TypeConfig,
    className?: string
};

const TypeIcon = (props: Props) => {

    return <div className={props.className}
                style={{
                    backgroundColor: props.typeConfig.backgroundColor,
                    borderRadius: '100%',
                    borderColor: props.typeConfig.color,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    padding: 10,
                    lineHeight: 0,
                    display: 'inline-block',
                    minWidth: 'max-content',
                    maxWidth: 'max-content'
                }}>
        <img src={props.typeConfig.icon?.src}
             alt={props.typeConfig.name}
             style={{ width: 20, height: 20 }}/>
    </div>;
};

export default TypeIcon;
