import { CategoryId, GroupId } from '../../../../models/aliases';
import { Category, Group, Person } from '../../../../models/core';
import { Services } from '../../../../models/services';
import { Snapshot } from '../../../../models/snapshot';
import { Type } from '../../../../models/type';
import { GraphData, GraphLink, GraphNode } from '../graph-page-view';
import { EXPERIENCES_ID, Force } from './shared';

/**
 * Creates an organogram containing Groups, Teams and team members directly coupled to the team nodes
 * @param person
 * @param snapshot
 * @param services
 */
export default async function personGraph(person: Person, snapshot: Snapshot, services: Services): Promise<GraphData> {
    const experiencesPromise = services.experiences.getExperiencesForPerson(person.id, snapshot.date);

    const nodes: GraphNode[] = [];
    const links: GraphLink[] = [];

    const defaultNode: GraphNode = { type: Type.Person, id: person.id, name: person.name, data: person };
    nodes.push(defaultNode);

    nodes.push({ type: Type.Dummy, id: EXPERIENCES_ID, name: 'Ervaringen' });
    links.push({ type: Type.Dummy, source: EXPERIENCES_ID, target: person.id, force: Force.Huge });

    const uniqueGroups = new Set<GroupId>();

    snapshot.teams.forEach(team => {
        let isMemberOfTeam = false;
        team.roles.forEach(role => {
            if (role.members.find(it => it === person.id)) {
                nodes.push({ type: Type.Role, id: role.id, name: role.name, data: role });
                links.push({ type: Type.Role, source: person.id, target: role.id, force: Force.Large });
                links.push({ type: Type.Team, source: role.id, target: team.id, force: Force.Medium });
                isMemberOfTeam = true;
            }
        });
        if (isMemberOfTeam) {
            nodes.push({ type: Type.Team, id: team.id, name: team.name, data: team });
            let source = team.id;
            let groupId: GroupId | null = team.group;
            do {
                links.push({ type: Type.Group, source, target: groupId, force: Force.Small });
                if (uniqueGroups.has(groupId)) {
                    break;
                }

                const group: Group = snapshot.groupMap.get(groupId)!!;
                nodes.push({ type: Type.Group, id: group.id, name: group.name, data: group });
                uniqueGroups.add(groupId);

                source = groupId;
                groupId = group.superGroup;
            } while (groupId);
        }
    });

    const uniqueCategories = new Set<Category>();

    (await experiencesPromise).forEach(experience => {
        const innovation = snapshot.innovationMap.get(experience.innovation)!!;
        nodes.push({
            type: Type.Experience,
            id: experience.id,
            name: innovation.name,
            data: experience
        });

        let source = experience.id;
        let categoryId: CategoryId | null = innovation.category;
        while (true) {
            const category: Category = snapshot.categoryMap.get(categoryId)!!;
            links.push({ type: Type.Category, source, target: categoryId, force: Force.Huge });
            uniqueCategories.add(category);

            if (!category.superCategory) {
                links.push({ type: Type.Category, source: categoryId, target: EXPERIENCES_ID, force: Force.Large });
                break;
            }

            source = categoryId;
            categoryId = category.superCategory;
        }
    });

    uniqueCategories.forEach(category => {
        nodes.push({ type: Type.Category, id: category.id, name: category.name, data: category });
    });

    const func = snapshot.functionMap.get(person.function)!!;
    nodes.push({ type: Type.Function, id: person.function, name: func.name, data: func });
    links.push({ type: Type.Function, source: person.id, target: person.function, force: Force.Medium });

    return { nodes, links, defaultNode };
}
