import { TeamId } from '../../../../models/aliases';
import { Function } from '../../../../models/core';
import { Snapshot } from '../../../../models/snapshot';
import { Type } from '../../../../models/type';
import { GraphData, GraphLink, GraphNode } from '../graph-page-view';
import { Force, FUNCTIONS_ID } from './shared';

/**
 * Creates an organogram containing Groups, Teams and team members directly coupled to the team nodes
 * @param func
 * @param snapshot
 */
export default async function functionGraph(func: Function | null, snapshot: Snapshot): Promise<GraphData> {
    const nodes: GraphNode[] = [];
    const links: GraphLink[] = [];

    let defaultNode: GraphNode = { type: Type.Dummy, id: FUNCTIONS_ID, name: 'Functies' };
    nodes.push(defaultNode);

    snapshot.functions.forEach(func => {
        nodes.push({ type: Type.Function, id: func.id, name: func.name, data: func });
        links.push({ type: Type.Function, source: func.id, target: FUNCTIONS_ID, force: Force.Tiny });
    });

    if (!func) {
        return { nodes, links, defaultNode };
    }

    const uniqueTeams = new Set<TeamId>();

    for (const person of snapshot.persons) {
        if (person.function !== func.id) {
            continue;
        }
        nodes.push({ type: Type.Person, id: person.id, name: person.name, data: person });

        snapshot.teams.forEach(team => {
            team.roles.forEach(role => {
                const isTeamMember = role.members.find(it => it === person.id);
                if (isTeamMember) {
                    links.push({ type: Type.Person, source: person.id, target: team.id, force: Force.Large });
                    if (!uniqueTeams.has(team.id)) {
                        nodes.push({ type: Type.Team, id: team.id, name: team.name, data: team });
                        links.push({ type: Type.Team, source: team.id, target: func.id, force: Force.Medium });
                        uniqueTeams.add(team.id);
                    }
                }
            });
        });
    }

    return { nodes, links, defaultNode };
}
